<span class="entity-group">
    <ng-container *ngFor="let part of parts">
        <ng-container *ngIf="!isNewline(part.text); else newLine">
            <span class="entity phi" [attr.id]="part.id" appRect appEntity [ids]="part.ids"
                [ngClass]="{'empty-space':isEmptySpace(part.text), 'recognized': isRecognized(recognizedEntities$ | async,part.ids)}">{{part.text}}</span>
        </ng-container>
        <ng-template #newLine>
            <pre class="new-line phi">{{part.text}}</pre>
        </ng-template>
    </ng-container>
</span>