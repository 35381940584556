import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import IntervalTree from '@flatten-js/interval-tree';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RectDirective } from '../directives/rect/rect.directive';
import { SpanChunk, SpanResolver } from '../helpers/span-resolver';
import { Segment } from '../section/section.component';
import { SpanService } from '../services/span.service';
import { State } from '../store';
import { selectDisabledTypes, selectEntitiesMap } from '../store/entity/entity.reducer';
import { getSelectedOntology } from '../store/ontologies/ontologies.reducer';
import { Span } from '../store/span/span.model';


@Component({
  selector: 'app-entity-group',
  templateUrl: './entity-group.component.html',
  styleUrls: ['./entity-group.component.scss']
})
export class EntityGroupComponent implements OnInit, AfterViewInit {

  @Input()
  segment: Segment;

  @ViewChildren(RectDirective)
  rects!: QueryList<RectDirective>;

  parts: SpanChunk[];
  recognizedEntities$: Observable<string[]>;
  selectedOntology$: Observable<string>;

  get nested() {
    return this.segment.nested;
  }

  constructor(private store: Store<State>, private spanService: SpanService) {
    this.parts = [];
  }

  ngOnInit(): void {
    const resolver = new SpanResolver();
    this.parts = resolver.flatten(this.nested)
      .map(part => {
        const [start, end] = part.bounds;
        const text = this.segment.text
          .substring(start, end + 1)
          .replace(/[\r\n]+(?=[^\s])/gm, ' ');
        part.text = text;
        return part;
      });
    this.selectedOntology$ = this.store.select(getSelectedOntology);
    this.recognizedEntities$ = combineLatest([
      this.selectedOntology$,
      this.store.select(selectEntitiesMap),
      this.store.select(selectDisabledTypes)
    ])
      .pipe(
        map(([selectedOntology, entities, disabledTypes]) => {
          const recognizedEntities: string[] = [];
          this.parts.forEach(part => {
            part.ids.filter(entityId => {
              const entity = entities[entityId];
              const entityOntologies = entity ? Object.keys(entity.entity_type) : [];
              const entityTypes = entity ? entityOntologies.map(t => entity.entity_type[t] as string) : [];
              if (entityOntologies.includes(selectedOntology) && !this.intersectingArrays(entityTypes, disabledTypes)) {
                recognizedEntities.push(entityId);
              }
            })
          });
          return recognizedEntities;
        })
      )
  }

  ngAfterViewInit(): void {
    if ('fonts' in document) {
      (document as any).fonts.ready.then(() => {
        this.publishSpans();
      });
    } else {
      this.publishSpans();
    }
  }

  private publishSpans() {
    const spanMap = this.rects
      .reduce<Map<string, Span>>((spans, rect) => {
        let el = rect.el.nativeElement;
        let x = 0;
        let y = 0;
        const {
          offsetHeight: height,
          offsetWidth: width,
        } = el;

        while (el && !el.classList.contains('vis-root')) {
          let {
            offsetLeft: x2,
            offsetTop: y2
          } = el;
          x += x2;
          y += y2;
          el = el.offsetParent as HTMLElement;
        }

        rect.ids.forEach(id => {
          let span = spans.get(id);
          if (!span) {
            const span: Span = {
              id,
              index: x,
              x,
              y,
              height,
              width,
              visible: true
            };
            spans.set(id, span);
          } else {
            span.x = Math.min(x, span.x);
            span.y = Math.min(y, span.y);
            span.width += width;
            span.height = Math.max(span.height, height);
          }
        });
        return spans;
      }, new Map());
    if (spanMap.size > 0) {
      const spans = [...spanMap.values()]
      spans.forEach(span => {
        this.spanService.setSpan(span);
      });
    }
  }

  isRecognized(recognizedEntities: string[], ids: string[]) {
    return this.intersectingArrays(recognizedEntities, ids);
  }

  isEmptySpace(text: string): boolean {
    return !text ||
      (text.length === 1 && !!text.match(/^\s+$/));
  }

  isNewline(text: string): boolean {
    return !text ||
      (text.length === 1 && !!text.match(/[\r\n]/));
  }

  private intersectingArrays<T>(a: T[], b: T[]): boolean {
    if (a.length === 0 && b.length === 0) {
      return false;
    }
    return b.some(lastIntersection => a.indexOf(lastIntersection) !== -1);
  }

}
