import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of, timer } from 'rxjs';
import { catchError, concatMap, map, mapTo, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Action } from '@ngrx/store';
import { UserService } from '../services/user.service';
import * as UserActions from './user.actions';

enum LocalStorageKeys {
  USERNAME = 'username',
  USER_ID = 'user_id',
  TOKEN = 'token'
}


@Injectable()
export class UserEffects {

  loadUser$ = createEffect(() => {
    return this.actions$.pipe(

      ofType(UserActions.loadUser),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        EMPTY.pipe(
          map(data => UserActions.loadUserSuccess({ data })),
          catchError(error => of(UserActions.loadUserFailure({ error }))))
      )
    );
  });

  signIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.signIn),
      switchMap(({ username, password }) => {
        return this.userService.signIn(username, password)
          .pipe(
            map(user => UserActions.signInSuccess({
              ...user,
              auth_token: user.auth_token,
              user_name: user.user_name || username,
              user_id: user.user_id || username
            })),
            catchError(error => of(UserActions.signInFail({
              username,
              error
            })))
          );
      })
    )
  });

  signInSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.signInSuccess),
      switchMap(user => {
        const token = user.auth_token || localStorage.getItem(LocalStorageKeys.TOKEN);
        return this.userService.getUserInfo(user.user_id, token)
          .pipe(
            tap(userDetails => {
              localStorage.setItem(LocalStorageKeys.USERNAME, user.user_name);
              localStorage.setItem(LocalStorageKeys.USER_ID, user.user_id);
              if (user.auth_token) {
                localStorage.setItem(LocalStorageKeys.TOKEN, token);
              }
            }),
            switchMap(user => {
              user.auth_token = localStorage.getItem(LocalStorageKeys.TOKEN);
              this.autoSignOut(user.auth_token);
              return of(UserActions.userLoaded(user));
            }),
            catchError(error => of(UserActions.loadUserFailure({ error })))
          )
      }),
    );
  });

  fetchUserStatus = createEffect(() => this.actions$.pipe(
    ofType(UserActions.userLoaded),
    map(() => UserActions.getUserStatus())
  ));

  getUserStatus$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.getUserStatus),
    switchMap(() => this.userService.getUserStatus().pipe(
      map(userStatus => UserActions.getUserStatusSuccess(userStatus)),
      catchError(error => of(UserActions.getUserStatusFail({ error })))
    ))
  ));

  getQuotas$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.userLoaded),
    switchMap(() =>
      timer(0, 10000).pipe(takeUntil(this.actions$.pipe(ofType(UserActions.getQuotasFailure))))
    ),
    switchMap(() => this.userService.getUserStatus().pipe(
      map(userStatus =>
        UserActions.getQuotasSuccess({ reports_processed: userStatus.reports_processed, reports_quota: userStatus.reports_quota })
      ),
      catchError(error => of(UserActions.getQuotasFailure({ error })))
    )))
  );

  changePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.changePassword),
      switchMap(({ username, password, newPassword }) => {
        return this.userService.changePassword(username, password, newPassword)
          .pipe(
            map(success => UserActions.changePasswordSuccess({ username, password: newPassword })),
            catchError(error => of(UserActions.changePasswordError({ error })))
          )
      })
    );
  });

  autoSignInAfterPasswordChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.changePasswordSuccess),
      switchMap(({ username, password }) => {
        return of(UserActions.signIn({ username, password }));
      })
    )
  });

  signup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.signup),
      switchMap((payload) => {
        return this.userService.signup(payload).pipe(
          map(success => UserActions.signupSuccess(payload)),
          catchError(error => of(UserActions.signupFail({ error })))
        )
      })
    );
  });

  sendForgotPasswordEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.sendForgotPasswordEmail),
      switchMap(({ email }) => {
        return this.userService.sendForgotPasswordEmail(email).pipe(
          map(success => UserActions.sendForgotPasswordEmailSuccess()),
          catchError(error => of(UserActions.sendForgotPasswordEmailFail({ error })))
        );
      })
    );
  });

  sendVerificationEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.sendVerificationEmail),
      switchMap(() => {
        return this.userService.sendVerificationEmail().pipe(
          map(success => UserActions.sendVerificationEmailSuccess()),
          catchError(error => of(UserActions.sendVerificationEmailFail({ error })))
        );
      })
    );
  });

  verifyEmail$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.verifyEmail),
    switchMap(({ email, verificationCode }) => {
      return this.userService.verifyEmail(email, verificationCode)
        .pipe(
          map(success => UserActions.verifyEmailSuccess()),
          catchError(error => of(UserActions.verifyEmailFail({ error })))
        )
    })
  ));

  acceptEula$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.acceptEula),
    switchMap(({ eula }) => {
      return this.userService.acceptEula(eula).pipe(
        map(success => UserActions.acceptEulaSuccess()),
        catchError(error => of(UserActions.acceptEulaFail({ error })))
      );
    })
  ));

  expireSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUserFailure),
      switchMap(({ error }) => of(UserActions.signOut({ error })))
    );
  });

  signOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.signOut),
      switchMap(() => this.userService.signOut().pipe(
        map(()=>{
          localStorage.removeItem(LocalStorageKeys.USERNAME);
          localStorage.removeItem(LocalStorageKeys.USER_ID);
          localStorage.removeItem(LocalStorageKeys.TOKEN);
          return UserActions.noLocalUser();
        })
      ))
    );
  });

  hydrateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('[AppState] Fetch App Config Success'),
      mapTo(({ userId: localStorage.getItem(LocalStorageKeys.USER_ID) })),
      switchMap(({ userId }) => {
        if (!userId) {
          return of(UserActions.noLocalUser());
        } else {
          const token = localStorage.getItem(LocalStorageKeys.TOKEN);
          return this.userService.getUserInfo(userId, token)
            .pipe(
              map(user => {
                return UserActions.userLoaded({
                  ...user,
                  user_name: user.user_name || userId,
                  user_id: user.user_name || userId,
                  auth_token: token
                });
              }),
              catchError(error => of(UserActions.loadUserFailure({ error })))
            );
        }
      })
    );
  });

  hydrateEULA$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('[AppState] Fetch App Config Success'),
      switchMap(() => {
        return this.userService.getEula().pipe(
          map(({ EULA }) => UserActions.setEULA({ eula: EULA }))
        )
      })
    );
  });

  autoSignOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.userLoaded),
      tap(() => {
        const token = localStorage.getItem(LocalStorageKeys.TOKEN);
        this.autoSignOut(token);
      })
    );
  }, {
    dispatch: false
  })



  constructor(
    private actions$: Actions,
    private userService: UserService
  ) { }

  private parseJwt(token: string) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    } catch (error) {
      console.warn(error);
      return {
        exp: 2147483647
      }
    }
  };


  private autoSignOut(JWTToken: string) {
    const now = new Date().getTime();
    const jwt = this.parseJwt(JWTToken);
    const signOutDuration = Math.max(jwt.exp * 1000 - now, 0);
    this.userService.setSignOutTimer(signOutDuration);
  }
}
