import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Section } from './section.model';
import * as SectionActions from './section.actions';

export const sectionsFeatureKey = 'sections';

export interface State extends EntityState<Section> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Section> = createEntityAdapter<Section>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(SectionActions.addSection,
    (state, action) => adapter.addOne(action.section, state)
  ),
  on(SectionActions.upsertSection,
    (state, action) => adapter.upsertOne(action.section, state)
  ),
  on(SectionActions.addSections,
    (state, action) => adapter.addMany(action.sections, state)
  ),
  on(SectionActions.upsertSections,
    (state, action) => adapter.upsertMany(action.sections, state)
  ),
  on(SectionActions.updateSection,
    (state, action) => adapter.updateOne(action.section, state)
  ),
  on(SectionActions.updateSections,
    (state, action) => adapter.updateMany(action.sections, state)
  ),
  on(SectionActions.deleteSection,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(SectionActions.deleteSections,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(SectionActions.loadSections,
    (state, action) => adapter.setAll(action.sections, state)
  ),
  on(SectionActions.clearSections,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

const getSectionsState = createFeatureSelector<State>(sectionsFeatureKey);
export const selectAllSections = createSelector(getSectionsState, selectAll);
export const selectSectionMap = createSelector(getSectionsState, selectEntities);
