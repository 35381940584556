import { AfterContentInit, AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import type { ButtonComponent, TextInputComponent } from 'elements-lib';
import { UserActions, UserState } from 'pages-lib';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith, takeWhile, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'lib-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('username')
  usernameInput: TextInputComponent;

  @ViewChild('password')
  passwordInput: TextInputComponent;

  @ViewChild('newPassword1')
  newPasswordInput1: TextInputComponent;

  @ViewChild('newPassword2')
  newPasswordInput2: TextInputComponent;

  @ViewChild('button')
  updateButton: ButtonComponent;

  submitDisabled$: BehaviorSubject<boolean>;

  formData$: Observable<[string, string, string, string]>;
  alive: boolean;

  constructor(private store: Store<UserState>) {
    this.submitDisabled$ = new BehaviorSubject(true);
  }

  ngOnInit(): void {
    this.alive = true;
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  ngAfterViewInit(): void {
    this.formData$ = combineLatest([
      this.usernameInput.value$,
      this.passwordInput.value$,
      this.newPasswordInput1.value$,
      this.newPasswordInput2.value$
    ]);
    this.formData$.pipe(
      map(([username, password, new1, new2]) => {
        return !(!!username && !!password && !!new1 && !!new2 && new1 === new2);
      }),
      startWith(false),
      takeWhile(() => this.alive)
    ).subscribe({
      next: disabled => this.submitDisabled$.next(disabled)
    });

    this.updateButton.click$.pipe(
      withLatestFrom(this.formData$),
      takeWhile(() => this.alive)
    ).subscribe(([e, [username, password, new1, new2]]) => {
      if (new1 === new2) {
        this.store.dispatch(UserActions.changePassword({ username, password, newPassword: new1 }));
      }
    });
  }

}
