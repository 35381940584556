import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromSpans from './span/span.reducer';
import * as fromEntity from './entity/entity.reducer';
import * as fromSection from './section/section.reducer';
import * as fromConcept from './concept/concept.reducer';
import * as fromOntologies from './ontologies/ontologies.reducer';
import * as fromRelation from './relation/relation.reducer';
import * as fromVisualizer from './visualizer/visualizer.reducer';

export const visualizerFeatureKey = 'visualizer';

export interface State {
  [fromSpans.spansFeatureKey]: fromSpans.State,
  [fromEntity.entitiesFeatureKey]: fromEntity.State,
  [fromSection.sectionsFeatureKey]: fromSection.State,
  [fromConcept.conceptsFeatureKey]: fromConcept.State,
  [fromOntologies.ontologiesFeatureKey]: fromOntologies.State,
  [fromRelation.relationsFeatureKey]: fromRelation.State,
  [fromVisualizer.visualizerFeatureKey]: fromVisualizer.State
}

export const reducers: ActionReducerMap<State> = {
  [fromSpans.spansFeatureKey]: fromSpans.reducer,
  [fromEntity.entitiesFeatureKey]: fromEntity.reducer,
  [fromSection.sectionsFeatureKey]: fromSection.reducer,
  [fromConcept.conceptsFeatureKey]: fromConcept.reducer,
  [fromOntologies.ontologiesFeatureKey]: fromOntologies.reducer,
  [fromRelation.relationsFeatureKey]: fromRelation.reducer,
  [fromVisualizer.visualizerFeatureKey]: fromVisualizer.reducer
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
export const getVisualizerState = createFeatureSelector<State>(visualizerFeatureKey);
