import {
  Directive,
  Inject,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeWhile, tap, withLatestFrom } from 'rxjs/operators';
import { EnabledFeaturesInjectionToken } from './feature-flag.injection';

@Directive({
  selector: '[FeatureFlag]',
})
export class FeatureFlagDirective implements OnDestroy {
  alive: boolean;

  @Input() set FeatureFlag(featureName: string) {
    this.flag$.next(featureName);
  }

  private flag$: BehaviorSubject<string>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    @Inject(EnabledFeaturesInjectionToken) private features$: Observable<string[]>
  ) {
    this.alive = true;
    this.flag$ = new BehaviorSubject('');
    this.flag$
      .pipe(
        withLatestFrom(this.features$),
        tap(([flag, enabledFeatures]) => {
          if (this.isFeatureEnabled(flag, enabledFeatures)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainer.clear();
          }
        }),
        takeWhile(() => this.alive)
      )
      .subscribe();
  }
  ngOnDestroy(): void {
    this.alive = false;
  }

  private isFeatureEnabled(flag: string, enabledFeatures: string[]) {
    return enabledFeatures.includes(flag);
  }
}
