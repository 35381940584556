<div class="nav-bar-wrapper">
    <div class="nav-bar">
        <lib-top-nav-button
          *ngFor="let button of buttons"
          [navElement]="button"
          [isActive]="active === button.id"
          (clickEvent)="onButtonClick($event)"
        ></lib-top-nav-button>
    </div>
</div>
