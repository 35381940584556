import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Span } from './span.model';

export const loadSpans = createAction(
  '[Span/API] Load Spans', 
  props<{ spans: Span[] }>()
);

export const addSpan = createAction(
  '[Span/API] Add Span',
  props<{ span: Span }>()
);

export const upsertSpan = createAction(
  '[Span/API] Upsert Span',
  props<{ span: Span }>()
);

export const addSpans = createAction(
  '[Span/API] Add Spans',
  props<{ spans: Span[] }>()
);

export const upsertSpans = createAction(
  '[Span/API] Upsert Spans',
  props<{ spans: Span[] }>()
);

export const updateSpan = createAction(
  '[Span/API] Update Span',
  props<{ span: Update<Span> }>()
);

export const updateSpans = createAction(
  '[Span/API] Update Spans',
  props<{ spans: Update<Span>[] }>()
);

export const deleteSpan = createAction(
  '[Span/API] Delete Span',
  props<{ id: string }>()
);

export const deleteSpans = createAction(
  '[Span/API] Delete Spans',
  props<{ ids: string[] }>()
);

export const clearSpans = createAction(
  '[Span/API] Clear Spans'
);
