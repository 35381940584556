import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, timer } from 'rxjs';
import {
  map,
  retry,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { NLPOutput } from './output-type';
import { ProcessingOptions } from './processing-options/processing-options';
import { State } from './store/reports.reducer';
import { reportProcessingFail, updateProcessReportStatus } from './store/reports.actions';

interface SubmitReportResult {
  id: string;
  task_id: string;
}

interface CheckStatusResult {
  completion: {
    completed: number;
    total: number;
  };
  state: ProcessingState;
}

interface PollResult extends CheckStatusResult {
  taskId: string;
  error?: any;
};

export enum ProcessingState {
  SETUP = 'setup',
  PROCESSING = 'processing',
  FAILURE = 'failure',
  CANCELLED = 'cancelled',
  SUCCESS = 'success',
  ERROR = 'error',
}

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  host: string;

  constructor(private http: HttpClient, @Inject('emtelliproServer') private emtelliproServer$: Observable<string>, private store: Store<State>) {
  }

  validateURl(host: string) {
    return this.http.get(`${host}/emtellipro/user`);
  }

  sendReport(
    reportText: string,
    file: File,
    category: string,
    subcategory: string,
    advancedOptions?: ProcessingOptions
  ) {
    return this.emtelliproServer$.pipe(
      take(1),
      switchMap((host) => {
        const fileName = file ? file.name : 'visual-client';
        const form = new FormData();
        form.set(`category[${fileName}]`, category);
        form.set(`subcategory[${fileName}]`, subcategory);
        form.set(`id[${fileName}]`, fileName);
        if (file) {
          form.set(`type[${fileName}]`, 'default');
          form.set(`file[${fileName}]`, file, fileName);
        }
        if (reportText) {
          form.set(`type[${fileName}]`, 'plain');
          form.set(
            `file[${fileName}]`,
            new Blob([reportText], {
              type: 'text/plain',
            }),
            fileName
          );
        }
        const url = `${host}/emtellipro/submit`;
        const options = advancedOptions
          ? {
            params: {
              features: this.serialize(advancedOptions),
            },
          }
          : undefined;
        return this.http.post<SubmitReportResult>(url, form, options);
      })
    );
  }

  serialize(advancedOptions: ProcessingOptions): string {
    return advancedOptions.ids
      .reduce<string[]>((enabledFeatures, featureId) => {
        const feature = advancedOptions.entities[featureId];
        if (feature.value === true) {
          enabledFeatures.push(feature.option);
        }
        return enabledFeatures;
      }, [])
      .join(',');
  }

  pollProcessingStatus(taskId: string): Observable<PollResult> {
    return this.emtelliproServer$.pipe(
      take(1),
      switchMap((host) => {
        const url = `${host}/emtellipro/status/${taskId}?timeout=10`;
        return this.http.get<CheckStatusResult>(url).pipe(
          tap((result) => {
            this.store.dispatch(
              updateProcessReportStatus({
                taskId,
                state: result.state,
              })
            );
          }),
          map((result) => {            
            switch (result.state) {
              case ProcessingState.SUCCESS:
                return {
                  completion: result.completion,
                  id: taskId,
                  state: result.state,
                  task_id: taskId,
                  taskId,
                  error: null
                } as PollResult;
              case ProcessingState.SETUP:
              case ProcessingState.PROCESSING:
                throw new Error('Not done processing');
              case ProcessingState.FAILURE:
                return {
                  ...result,
                  taskId,
                  error: {
                    message: 'Failed to process report',
                  }
                }
              default:
                break;
            }
          }),
          retry({
            count: 100,
            delay: (error, retryCount) => timer(retryCount * 1000),
          })
        );
      })
    );
  }

  getReportResults(taskId: string) {
    return this.emtelliproServer$.pipe(
      take(1),
      switchMap((host) => {
        const url = `${host}/emtellipro/result/${taskId}?format=emtellipro-json-2`;
        return this.http.get<NLPOutput>(url);
      })
    );
  }
}
