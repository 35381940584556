import { Injectable, Type } from '@angular/core';
import { ComponentInstantiator } from '../core/component-Instantiator';
import { DialogContainerComponent } from './dialog-container/dialog-container.component';
import { DialogOptions } from "./dialog.options";

@Injectable({
  providedIn: 'root'
})
export class DialogService extends ComponentInstantiator {

  open<T>(component: Type<T>, options?: Partial<DialogOptions>): {
    container: DialogContainerComponent;
    content: T;
  } {
    return super._open(component, DialogContainerComponent, options);
  }
}
