import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from './user.reducer';

export const selectUserState = createFeatureSelector<fromUser.State>(
  fromUser.userFeatureKey
);

export const selectUsername = createSelector(selectUserState, state => state.name);
export const selectUserError = createSelector(selectUserState, state => state.error);
export const selectInProgress = createSelector(selectUserState, state => state.inProgress);
export const selectLoggedIn = createSelector(selectUserState, state => state.loggedIn);
export const selectAuthStep = createSelector(selectUserState, state => state.step);
export const selectFeatures = createSelector(selectUserState, state => state.features);
export const selectEULA = createSelector(selectUserState, state => state.eula);
export const selectErrors = createSelector(selectUserState, state => state.errors);
export const selectAccountStatus = createSelector(selectUserState, state => state.accountStatus);
export const selectVerificationEmails = createSelector(selectUserState, state => state.verificationEmails);
export const selectQuotas = createSelector(selectUserState, state => state.quotas);
export const selectToken = createSelector(selectUserState, state => state.token);
export const selectInitials = createSelector(selectUserState, state => {
  return !!state.firstName && !!state.lastName ? state.firstName.charAt(0) + state.lastName.charAt(0): null;
});