import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';

@Component({
  selector: 'lib-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements AfterViewInit {

  @Input()
  disabled: boolean;

  @Input()
  type: 'submit' | 'reset' | 'button' = 'button';

  click$: Observable<Event>;

  @ViewChild('button')
  private button: ElementRef<HTMLButtonElement>;

  constructor() { }
  ngAfterViewInit(): void {
    this.click$ = fromEvent(this.button.nativeElement, 'click');
  }

}
