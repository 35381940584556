import { Entity } from './entity.model';

export const collectAllTypes = (entities: Entity[], selectedOntology: string) => {
    const map = new Map<string, number>();
    entities.forEach(entity => {
        const entityType = entity.entity_type[selectedOntology];
        const occurrences = map.get(entityType);
        if (entityType) {
            map.set(entityType, (occurrences || 0) + 1)
        }
    });
    return map;
}