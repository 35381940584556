import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

export enum ChipStatus {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
  ACTIVE = 'active'
}

export interface Chip {
  id: string;
  label: string;
  status: ChipStatus;
  count?: number;
}

@Component({
  selector: 'lib-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {
  @Input()
  chip: Chip;

  @Output()
  clicked = new EventEmitter<Chip>();

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.clicked.emit(this.chip);
  }

}
