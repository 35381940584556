import { AfterViewInit, Component, ViewContainerRef } from '@angular/core';
import { DialogService } from './dialog.service';


@Component({
  selector: 'lib-dialog',
  template: '',
  styles: ['']
})
export class DialogComponent implements AfterViewInit {
  constructor(private viewContainerRef: ViewContainerRef, private dialog: DialogService) { }
  ngAfterViewInit(): void {    
    this.dialog.viewContainerRef = this.viewContainerRef;
  }

}
