import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DialogService } from 'elements-lib';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss']
})
export class SessionExpiredComponent {

  @Output()
  signInClicked: EventEmitter<void>;

  constructor() {
    this.signInClicked = new EventEmitter<void>();
  }

  onSignIn() {
    this.signInClicked.emit();
  }

}
