import { Injectable } from '@angular/core';
import IntervalTree from '@flatten-js/interval-tree';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, share, tap } from 'rxjs/operators';
import { Rect } from '../directives/label/label.directive';

@Injectable({
  providedIn: 'root'
})
export class LabelService {

  public visibleLabels$: Observable<string[]>;
  private labelMap: Map<string, Rect>;
  private labelOffsetMap: Map<string, number>;
  private visibleLabels: BehaviorSubject<string[]>;
  private hTree: IntervalTree<string>;
  private vTree: IntervalTree<string>;



  constructor() {
    this.labelMap = new Map();
    this.labelOffsetMap = new Map();
    this.visibleLabels = new BehaviorSubject([]);
    this.hTree = new IntervalTree<string>();
    this.vTree = new IntervalTree<string>();
    this.visibleLabels$ = this.visibleLabels.pipe(
      debounceTime(30),
      tap(() => {
        this.labelOffsetMap.clear();
      }),
      share()
    );
  }

  add(id: string, rect: Rect) {
    this.labelMap.set(id, rect);
    const {
      hStart,
      hEnd,
      vStart,
      vEnd
    } = this.getBounds(rect);
    if (!this.hTree.exist([hStart, hEnd], id)) {
      this.hTree.insert([hStart, hEnd], id);
    }
    if (!this.vTree.exist([vStart, vEnd], id)) {
      this.vTree.insert([vStart, vEnd], id);
    }
    this.updateVisibleLabels();
  }

  remove(id: string) {
    const horizontal = this.hTree.items.find(x => x.value === id);
    const vertical = this.vTree.items.find(x => x.value === id);
    if (horizontal) {
      this.hTree.remove(horizontal.key, id);
    }
    if (vertical) {
      this.vTree.remove(vertical.key, id);
    }
    this.labelMap.delete(id);
    this.updateVisibleLabels();
  }

  setOffset(id: string, offset: number) {
    this.labelOffsetMap.set(id, offset);
  }

  getOffset(id: string) {
    return this.labelOffsetMap.get(id);
  }

  reset() {
    this.labelMap.clear();
    this.labelOffsetMap.clear();
    this.hTree = new IntervalTree<string>();
    this.vTree = new IntervalTree<string>();
  }

  search(rect: Rect, exclude?: string) {
    const {
      hStart,
      hEnd,
      vStart,
      vEnd
    } = this.getBounds(rect);
    const vertical = this.vTree.search([vStart, vEnd]).filter(vi => vi !== exclude) as string[];
    const horizontal = this.hTree.search([hStart, hEnd]).filter(vi => vi !== exclude) as string[];
    return {
      vertical,
      horizontal
    }
  }

  getRect(id: string) {
    return this.labelMap.get(id);
  }

  private updateVisibleLabels() {
    this.visibleLabels.next([...this.labelMap.keys()]);
  }

  private getBounds(rect: Rect) {
    const hStart = rect.x;
    const vStart = rect.y;
    const hEnd = rect.x + rect.width;
    const vEnd = rect.y + rect.height;
    return { hStart, hEnd, vStart, vEnd };
  }
}
