import { Injectable, Type } from '@angular/core';
import { ComponentInstantiator } from '../core/component-Instantiator';
import { SnackbarContainerComponent } from './snackbar-container/snackbar-container.component';
import { SnackBarOptions } from './snackbar.options';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService extends ComponentInstantiator {
  open<T>(component: Type<T>, options?: SnackBarOptions) {
    return super._open<T, SnackbarContainerComponent, SnackBarOptions>(component, SnackbarContainerComponent, options);
  }

}
