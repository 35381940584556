<lib-card-header>
    <h3>Change password</h3>
    <lib-button [disabled]="submitDisabled$ | async" #button>Update</lib-button>
</lib-card-header>
<lib-card-content>
    <form>
        <lib-text-input [for]="'username'" [label]="'Username'" tabindex="1" #username></lib-text-input>
        <lib-text-input [for]="'password'" [label]="'Password'" tabindex="2" [inputType]="'password'"
            #password></lib-text-input>
        <lib-text-input [for]="'newPassword1'" [label]="'New Password'" tabindex="3" [inputType]="'password'"
            #newPassword1></lib-text-input>
        <lib-text-input [for]="'newPassword2'" [label]="'Confirm Password'" tabindex="4" [inputType]="'password'"
            #newPassword2></lib-text-input>
    </form>
</lib-card-content>