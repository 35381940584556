export const categories = {
    Radiology: [
        'generic',
        'CR',
        'CT',
        'MG',
        'MR',
        'NM',
        'PT',
        'RF',
        'US',
        'XA',
    ],
    Clinical: [
        'generic',
        'Admission Note',
        'Discharge Summary',
        'ER Visit',
        'History & Physical',
        'Office Visit',
        'Pre-op',
        'Consult request',
        'SOAP Note',
        'Nutrition Assessment',
        'CCD',
    ],
    Surgical: [
        'generic',
        'Operative Note',
        'Clinic Note',
    ],
    Pathology: [
        'generic',
        'Surgical Pathology',
        'Molecular Pathology',
        'Hematopathology',
        'Cytopathology',
        'Dermatopathology',
    ],
    Gastroenterology: [
        'generic',
        'Endoscopy',
        'Endoscopic Ultrasound',
        'ERCP',
        'VCE',
        'Ostomy Exam',
        'Pouch Exam',
    ],
    Orthopedic: [
        'generic',
        'Consult note',
    ],
    Cardiology: [
        'generic',
        'ECG',
        'ETT',
        'Echocardiogram',
        'Holter',
        'EP Study',
        'Angiogram',
    ],
    Molecular: [
        'generic',
    ],
};