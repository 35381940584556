import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Entity } from './entity.model';

export const loadEntities = createAction(
  '[Entity/API] Load Entities',
  props<{ entities: Entity[] }>()
);

export const addEntity = createAction(
  '[Entity/API] Add Entity',
  props<{ entity: Entity }>()
);

export const upsertEntity = createAction(
  '[Entity/API] Upsert Entity',
  props<{ entity: Entity }>()
);

export const addEntities = createAction(
  '[Entity/API] Add Entities',
  props<{ entities: Entity[] }>()
);

export const upsertEntities = createAction(
  '[Entity/API] Upsert Entities',
  props<{ entities: Entity[] }>()
);

export const updateEntity = createAction(
  '[Entity/API] Update Entity',
  props<{ entity: Update<Entity> }>()
);

export const updateEntities = createAction(
  '[Entity/API] Update Entities',
  props<{ entities: Update<Entity>[] }>()
);

export const deleteEntity = createAction(
  '[Entity/API] Delete Entity',
  props<{ id: string }>()
);

export const deleteEntities = createAction(
  '[Entity/API] Delete Entities',
  props<{ ids: string[] }>()
);

export const clearEntities = createAction(
  '[Entity/API] Clear Entities'
);

export const toggleEntityType = createAction(
  '[Entity/API] Toggle Type',
  props<{ entityType: string }>()
);

export const toggleAll = createAction(
  '[Entity/API] Toggle All Types',
  props<{ disableAllTypes: boolean, selectedOntology: string }>()
);

export const setHighlighted = createAction(
  '[Entity/API] Set Highlighted',
  props<{ id: string }>()
);

export const setHighlightedSpans = createAction(
  '[Entity/API] Set Highlighted Spans',
  props<{ ids: string[] }>()
);
