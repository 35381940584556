import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, take, takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'lib-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements AfterViewInit, OnDestroy {

  @Input('for')
  id: string;

  @Input('label')
  label: string;

  @Input('disabled')
  disabled: boolean;

  @Input('inputType')
  inputType: string;

  @ViewChild('input')
  inputRef: ElementRef<HTMLInputElement>;

  @Output('debouncedChange')
  onChange: EventEmitter<string>;

  value$: BehaviorSubject<string>;
  empty$: BehaviorSubject<boolean>;
  inFocus$: BehaviorSubject<boolean>;
  private alive: boolean;

  constructor() {
    this.empty$ = new BehaviorSubject(true);
    this.value$ = new BehaviorSubject(null);
    this.inFocus$ = new BehaviorSubject(false);
    this.onChange = new EventEmitter();
    this.alive = true;
  }
  ngOnDestroy(): void {
    this.alive = false;
    this.empty$.complete();
  }

  ngAfterViewInit(): void {
    fromEvent(this.inputRef.nativeElement, 'input').pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map(() => this.inputRef.nativeElement.value),
      startWith(this.inputRef.nativeElement.value),
      takeWhile(() => this.alive)
    ).subscribe({
      next: value => {
        const isEmpty = !value || value.length === 0;
        this.empty$.next(isEmpty);
        this.value$.next(value);
        this.onChange.emit(value);
      },
      complete: () => {
        this.empty$.complete();
        this.value$.complete();
      }
    });

    fromEvent(this.inputRef.nativeElement, 'focus').pipe(
      takeWhile(() => this.alive)
    ).subscribe({ next: () => this.inFocus$.next(true) });
    fromEvent(this.inputRef.nativeElement, 'blur').pipe(
      takeWhile(() => this.alive)
    ).subscribe({ next: () => this.inFocus$.next(false) });

    fromEvent<AnimationEvent>(this.inputRef.nativeElement, 'animationstart')
      .pipe(
        tap(e => {
          if (e.animationName === 'onAutoFillStart') {
            const value = this.inputRef.nativeElement.value;
            this.empty$.next(false);
            this.value$.next(value);
            this.onChange.emit(value);
          }
        }),
        take(1),
      ).subscribe();
  }

  public clear() {
    this.inputRef.nativeElement.value = null;
    this.inputRef.nativeElement.dispatchEvent(new InputEvent('input'));
  }

  public update(value: string) {
    this.inputRef.nativeElement.value = value;
    this.inputRef.nativeElement.dispatchEvent(new InputEvent('input'));
  }
}
