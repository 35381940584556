import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAppState from './app-state.reducer';

export const selectAppState = createFeatureSelector<fromAppState.State>(
  fromAppState.appStateFeatureKey
);
export const selectIamServer = createSelector(selectAppState, state => state.iamServer);
export const selectEmtelliproServer = createSelector(selectAppState, state => state.serverOverride ? state.serverOverride : state.emtelliproServer);
export const selectAllowedExtensions = createSelector(selectAppState, state => state.allowedExtensions);
export const selectEnabledFeatures = createSelector(selectAppState, state => state.enabledFeatures);
export const selectSavedAdvancedFeatures = createSelector(selectAppState, state => state.advancedOptions);