import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { SpanService } from '../../services/span.service';
import { setHighlightedSpans } from '../../store/entity/entity.actions';
import { State } from '../../store/span/span.reducer';

@Directive({
  selector: '[appRect]'
})
export class RectDirective {
  @Input()
  set ids(ids: string[]) {
    this._ids = ids;
    ids
      .filter(id => !!id)
      .forEach(id => this.spanService.register(id));
  }

  get ids(): string[] {
    return this._ids;
  }

  private _ids: string[];

  constructor(public el: ElementRef, private store: Store<State>, private spanService: SpanService) {
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.store.dispatch(setHighlightedSpans({
      ids: this.ids
    }));
  }

}
