import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Concept } from './concept.model';

export const loadConcepts = createAction(
  '[Concept/API] Load Concepts', 
  props<{ concepts: Concept[] }>()
);

export const addConcept = createAction(
  '[Concept/API] Add Concept',
  props<{ concept: Concept }>()
);

export const upsertConcept = createAction(
  '[Concept/API] Upsert Concept',
  props<{ concept: Concept }>()
);

export const addConcepts = createAction(
  '[Concept/API] Add Concepts',
  props<{ concepts: Concept[] }>()
);

export const upsertConcepts = createAction(
  '[Concept/API] Upsert Concepts',
  props<{ concepts: Concept[] }>()
);

export const updateConcept = createAction(
  '[Concept/API] Update Concept',
  props<{ concept: Update<Concept> }>()
);

export const updateConcepts = createAction(
  '[Concept/API] Update Concepts',
  props<{ concepts: Update<Concept>[] }>()
);

export const deleteConcept = createAction(
  '[Concept/API] Delete Concept',
  props<{ id: string }>()
);

export const deleteConcepts = createAction(
  '[Concept/API] Delete Concepts',
  props<{ ids: string[] }>()
);

export const clearConcepts = createAction(
  '[Concept/API] Clear Concepts'
);
