import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { LabelService } from '../../services/label.service';
import * as VisualizerActions from '../visualizer/visualizer.actions';
import * as RelationActions from './relation.actions';
import { Relation, RelationLabels } from './relation.model';
import { relationsFeatureKey } from './relation.reducer';
import { NLPOutput } from 'pages-lib';


@Injectable()
export class RelationEffects {

  hydrateRelations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('[Reports] Fetch Report Success'),
      switchMap(({ data: nlpOutput }: { data: NLPOutput }) => {
        const relations = this.getRelations(nlpOutput).map(relation => {
          return <Relation>({
            id: relation.label,
            ...relation
          });
        });
        const loadRelations = RelationActions.loadRelations({ relations });
        const notifyLoadingDone = VisualizerActions.storeHydrated({
          storeType: relationsFeatureKey
        });
        return of(loadRelations, notifyLoadingDone);
      })
    );
  });


  clear$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('[Reports] Cancel Fetch Report'),
      tap(() => {
        this.labelService.reset();
      }),
      switchMap(() => of(RelationActions.clearRelations()))
    );
  });



  constructor(private actions$: Actions, private labelService: LabelService) { }

  private getRelations(nlpOutput: NLPOutput) {
    const experiencers = this.getExperiencers(nlpOutput);
    const followups = this.getFollowups(nlpOutput);
    const imageLinks = this.getImageLinks(nlpOutput);
    const measurements = this.getMeasurements(nlpOutput);
    const medications = this.getMedications(nlpOutput);
    const qualifiers = this.getQualifiers(nlpOutput);
    const temporalities = this.getTemporalities(nlpOutput);
    const reportedEvents = this.getReportedEvents(nlpOutput);
    const anatomicSites = this.getAnatomicSites(nlpOutput);
    return [
      ...experiencers,
      ...followups,
      ...imageLinks,
      ...measurements,
      ...medications,
      ...qualifiers,
      ...temporalities,
      ...reportedEvents,
      ...anatomicSites
    ];
  }


  private getQualifiers(nlpOutput: NLPOutput) {
    if (!nlpOutput.documents[0].relations.qualifiers) {
      return [];
    }
    return nlpOutput.documents[0].relations.qualifiers.map(qa => ({
      ...qa,
      from: [qa.args.qualifies.ref],
      to: [qa.args.qualifier.ref],
      type: 'Qualifier',
      labels: this.getLabels(qa)
    }));
  }

  private getMedications(nlpOutput: NLPOutput) {
    if (!nlpOutput.documents[0].relations.medications) {
      return [];
    }
    return nlpOutput.documents[0].relations.medications.map(med => ({
      ...med,
      from: [med.args.drug.ref],
      to: [
        ...med.args.dosage.map(x => x.ref),
        ...med.args.frequency.map(x => x.ref),
        ...med.args.mode.map(x => x.ref),
        ...med.args.modifier.map(x => x.ref),
        ...med.args.necessity.map(x => x.ref),
        ...med.args.quantity.map(x => x.ref),
        ...med.args.route.map(x => x.ref),
        ...med.args.duration.map(x => x.ref),
        ...med.args.indication.map(x => x.ref),
        ...med.args.date_time.map(x => x.ref)
      ],
      type: 'Medications',
      labels: this.getLabels(med)
    }));
  }

  private getMeasurements(nlpOutput: NLPOutput) {
    if (!nlpOutput.documents[0].relations.measurements) {
      return [];
    }
    return nlpOutput.documents[0].relations.measurements.map(m => {
      const labels = this.getLabels(m);
      return {
        ...m,
        from: [m.args.subject.ref],
        to: [m.args.value.ref],
        type: 'Measurement',
        labels
      }
    });
  }

  private getImageLinks(nlpOutput: NLPOutput) {
    if (!nlpOutput.documents[0].relations.imagelinks) {
      return [];
    }
    return nlpOutput.documents[0].relations.imagelinks.map(iLnk => {
      const labels = this.getLabels(iLnk);
      return {
        ...iLnk,
        from: iLnk.args.references.map(imgR => imgR.ref),
        to: iLnk.args.image_findings.map(imgF => imgF.ref),
        type: 'ImageLink',
        labels
      }
    });
  }

  private getFollowups(nlpOutput: NLPOutput) {
    if (!nlpOutput.documents[0].relations.followups) {
      return [];
    }
    return nlpOutput.documents[0].relations.followups.map(fu => {
      const labels = this.getLabels(fu);
      return {
        ...fu,
        from: fu.args.procedures.map(pro => pro.ref),
        to: [
          ...fu.args.reasons.map(rs => rs.ref),
          ...(fu.args.time_expression ? [fu.args.time_expression.ref] : [])
        ],
        type: 'Followup',
        labels
      }
    });
  }

  private getExperiencers(nlpOutput: NLPOutput): Relation[] {
    if (!nlpOutput.documents[0].relations.experiencers) {
      return [];
    }
    return nlpOutput.documents[0].relations.experiencers.map(xp => {
      const labels = this.getLabels(xp);
      return {
        ...xp,
        id: xp.label,
        from: [xp.args.experienced.ref],
        to: [xp.args.experiencer.ref],
        type: 'Experiencer',
        group: xp.label,
        labels
      }
    });
  }

  private getTemporalities(nlpOutput: NLPOutput) {
    if (!nlpOutput.documents[0].relations.temporalities) {
      return [];
    }
    return nlpOutput.documents[0].relations.temporalities.map(temp => {
      return {
        ...temp,
        id: temp.label,
        from: [temp.args.subject.ref],
        to: [temp.args.temporal_entity.ref],
        type: 'Temporalities',
        group: temp.label,
        labels: this.getLabels(temp)
      }
    })
  }

  private getReportedEvents(nlpOutput: NLPOutput) {
    if (!nlpOutput.documents[0].relations.reportedevents) {
      return [];
    }
    return nlpOutput.documents[0].relations.reportedevents.map(re => ({
      ...re,
      id: re.label,
      from: [re.args.subject.ref],
      to: [
        ...re.args.to_entities.map(t => t.ref),
        ...re.args.from_entities.map(f => f.ref),
        ...re.args.methods.map(t => t.ref),
        ...re.args.time_expressions.map(t => t.ref)
      ],
      type: 'Reported Event',
      group: re.label,
      labels: this.getLabels(re)
    }));
  }

  private getAnatomicSites(nlpOutput: NLPOutput) {
    if (!nlpOutput.documents[0].relations.anatomicsites) {
      return [];
    }
    return nlpOutput.documents[0].relations.anatomicsites.map(anatomicSite => ({
      ...anatomicSite,
      id: anatomicSite.label,
      from: [anatomicSite.args.situated_entity.ref],
      to: [anatomicSite.args.site.ref],
      type: 'Anatomic Site',
      group: anatomicSite.label,
      labels: this.getLabels(anatomicSite)
    }))
  }

  private getLabels(relation: any) {
    return Object.keys(relation.args).reduce<RelationLabels>((o, argKey) => {
      const label = argKey.replace(/_/g, ' ');
      const arg = relation.args[argKey];
      if (!arg) {
        return o;
      }
      if (Array.isArray(arg)) {
        arg.forEach(subArg => {
          o[subArg.ref] = label;
        });
      } else {
        o[arg.ref] = label;
      }
      return o;
    }, {});
  }

}
