import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';
import { Concept } from './concept.model';
import * as ConceptActions from './concept.actions';

export const conceptsFeatureKey = 'concepts';

export interface State extends EntityState<Concept> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Concept> = createEntityAdapter<Concept>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(ConceptActions.addConcept,
    (state, action) => adapter.addOne(action.concept, state)
  ),
  on(ConceptActions.upsertConcept,
    (state, action) => adapter.upsertOne(action.concept, state)
  ),
  on(ConceptActions.addConcepts,
    (state, action) => adapter.addMany(action.concepts, state)
  ),
  on(ConceptActions.upsertConcepts,
    (state, action) => adapter.upsertMany(action.concepts, state)
  ),
  on(ConceptActions.updateConcept,
    (state, action) => adapter.updateOne(action.concept, state)
  ),
  on(ConceptActions.updateConcepts,
    (state, action) => adapter.updateMany(action.concepts, state)
  ),
  on(ConceptActions.deleteConcept,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ConceptActions.deleteConcepts,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ConceptActions.loadConcepts,
    (state, action) => adapter.setAll(action.concepts, state)
  ),
  on(ConceptActions.clearConcepts,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

const getConceptState = createFeatureSelector(conceptsFeatureKey);
const selectAllConcepts = createSelector(getConceptState, selectAll);
const selectAllConceptEntities = createSelector(getConceptState, selectEntities);
export const selectConceptById = createSelector(
  selectAllConceptEntities,
  (concepts: Dictionary<Concept>, id: string) => {
    return concepts[id];
  }
);
export const selectConceptsByIds = createSelector(
  selectAllConceptEntities,
  (concepts: Dictionary<Concept>, ids: string[]) => {
    const filtered: Concept[] = [];
    return ids
      .map(id => {
        return concepts[id];
      })
      .filter(concept => !!concept);
  }
);