import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReports from './reports.reducer';

export const selectReportsState = createFeatureSelector<fromReports.State>(
  fromReports.reportsFeatureKey
);
export const selectProcessingStatus = createSelector(selectReportsState, state => state.processingStatus);
export const selectRawResult = createSelector(selectReportsState, state => state.raw);
export const selectSourceString = createSelector(selectReportsState, state => state.sourceString);
export const selectCategory = createSelector(selectReportsState, state => state.category);
export const selectSubcategory = createSelector(selectReportsState, state => state.subcategory);
export const selectProcessingError = createSelector(selectReportsState, state => state.error);
