import { ComponentRef, EventEmitter, Injectable, Type, ViewContainerRef } from "@angular/core";
import { take } from "rxjs/operators";

export interface Container<T, O> {
    setContent: (component: Type<T>, options?: Partial<O>) => ComponentRef<T>;
    onClose: EventEmitter<void>;
    dismiss(): void;
    close(): void;
}

@Injectable({
    providedIn: 'root'
})
export abstract class ComponentInstantiator {
    set viewContainerRef(ref: ViewContainerRef) {
        this._viewContainerRef = ref;
    }
    private _viewContainerRef: ViewContainerRef;

    protected _open<T, U extends Container<T, O>, O>(component: Type<T>, componentContainer: Type<U>, options?: Partial<O>): {
        container: U;
        content: T;
    } {
        this._viewContainerRef.clear();
        const container = this._viewContainerRef.createComponent(componentContainer);
        const content = container.instance.setContent(component, options);
        container.instance.onClose
            .pipe(take(1))
            .subscribe(() => {
                this.close();
            });

        return {
            container: container.instance,
            content: content.instance
        };
    }

    close() {
        this._viewContainerRef.clear();
    }
}