import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lib-username-token',
  templateUrl: './username-token.component.html',
  styleUrls: ['./username-token.component.css']
})
export class UsernameTokenComponent implements OnInit {


  @Input()
  username: string;

  @Input()
  loggedIn: boolean;

  @Input()
  userToolTip: string;

  @Output('onLogout')
  onLogout: EventEmitter<void>;

  constructor() {
    this.onLogout = new EventEmitter();
  }

  ngOnInit(): void {
  }

  logout() {
    this.onLogout.emit();
  }

}
