import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { State } from '../app-state/store/app-state.reducer';
import * as AppStateSelectors from '../app-state/store/app-state.selectors';

@Injectable({
  providedIn: 'root'
})
export class ConfigProviderService {
  iamServer$: Observable<string>;
  emtelligentServer$: Observable<string>;

  constructor(private store: Store<State>) {
    this.iamServer$ = this.store.select(AppStateSelectors.selectIamServer);
    this.emtelligentServer$ = this.store.select(AppStateSelectors.selectEmtelliproServer);
  }
}
