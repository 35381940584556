import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as actions from './ontologies.actions';
import * as fromRoot from '../index';
export const ontologiesFeatureKey = 'ontologies';

export interface State {
  ids: string[];
  selected?: string;
}

export const initialState: State = {
  ids: [],
  selected: null
};


export const reducer = createReducer(
  initialState,
  on(actions.loadOntologies, (state, { ids }) => {
    return {
      ...state,
      ids
    }
  }),
  on(actions.selectOntology, (state, { selected }) => {
    return {
      ...state,
      selected
    }
  }),
  on(actions.clearOntologies, state => {
    return {
      ...initialState
    }
  })

);

export const getOntologiesState = createFeatureSelector<State>(ontologiesFeatureKey);
// export const getOntologiesState = (state: fromRoot.State) => state[ontologiesFeatureKey];
export const getAllOntologies = createSelector(getOntologiesState, state => state.ids);
export const getSelectedOntology = createSelector(getOntologiesState, state => state.selected);
