import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Output()
  onChecked: EventEmitter<boolean>;

  @Input()
  set checked(value: boolean) {
    this.checked$.next(value);
  }

  checked$: BehaviorSubject<boolean>;

  constructor() {
    this.checked$ = new BehaviorSubject(this.checked);
    this.onChecked = new EventEmitter(true);
  }

  ngOnInit(): void {
  }

  onChange(event: MouseEvent) {
    const value = (event.target as HTMLInputElement).checked
    this.checked$.next(value);
    this.onChecked.next(value);
  }

}
