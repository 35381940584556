import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { upsertSpans } from '../store/span/span.actions';
import { Span } from '../store/span/span.model';

@Injectable({
  providedIn: 'root'
})
export class SpanService {
  spans: Map<string, Span>;
  private refCount: number;

  constructor(private store: Store) {
    this.spans = new Map<string, Span>();
    this.refCount = 0;
  }

  register(spanId: string) {
    if (!this.spans.has(spanId)) {
      this.refCount++;
    }
    this.spans.set(spanId, null);
  }

  setSpan(span: Span) {
    if (this.spans.has(span.id)) {
      this.refCount--;
    }
    this.spans.set(span.id, span);
    if (this.refCount === 0) {
      this.store.dispatch(upsertSpans({
        spans: [...this.spans.values()]
      }));
    }
  }

  reset() {
    this.spans.clear();
    this.refCount = 0;
  }
}
