import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Injectable, Provider } from "@angular/core";
import { Store } from "@ngrx/store";
import { UserSelectors } from "pages-lib";
import { environment } from "projects/visual-client/src/environments/environment";
import { Observable } from "rxjs";
import { switchMap, take } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    public static DYNAMIC_HOST = '$baseURL';

    constructor(private store: Store) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.store.select(UserSelectors.selectUserState).pipe(
            take(1),
            switchMap(user => {
                const url = req.url.replace(AuthInterceptor.DYNAMIC_HOST, environment.emtelliproServer);
                const headerKeys = req.headers.keys();
                const headers = headerKeys.reduce<{ [key: string]: string }>((acm, key) => {
                    acm[key] = req.headers.get(key);
                    return acm;
                }, {});
                headers.auth_token = user.token;

                return next.handle(req.clone({
                    url,
                    setHeaders: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }));
            })
        );
    }
}

export const AuthInterceptorProvider: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
}