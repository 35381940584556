import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserSelectors } from 'pages-lib';
import { Observable } from 'rxjs';
import { filter, map, skip, skipUntil, switchMapTo, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private store: Store, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const doneLoadingUser$ = this.store.select(UserSelectors.selectInProgress).pipe(
      filter(isLoading => !isLoading),
    );
    return doneLoadingUser$.pipe(
      switchMapTo(this.store.select(UserSelectors.selectUsername)),
      map(username => {
        if (!username) {
          this.router.navigate(['/auth']);
        }
        return !!username;
      })
    )
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const doneLoadingUser$ = this.store.select(UserSelectors.selectInProgress).pipe(
      filter(isLoading => !isLoading),
    );
    return doneLoadingUser$.pipe(
      switchMapTo(this.store.select(UserSelectors.selectUsername)),
      map(username => {
        if (!username) {
          this.router.navigate(['/auth']);
        }
        return !!username;
      })
    )
  }
}
