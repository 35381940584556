import { animate, state, style, transition, trigger, AnimationEvent } from '@angular/animations';
import { Component, ComponentFactoryResolver, EventEmitter, Input, Output, Type, ViewChild } from '@angular/core';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { Container } from '../../core/component-Instantiator';
import { SnackbarDirective } from '../snackbar.directive';
import { SnackBarOptions } from '../snackbar.options';

@Component({
  selector: 'lib-snackbar-container',
  templateUrl: './snackbar-container.component.html',
  styleUrls: ['./snackbar-container.component.scss'],
  animations: [
    trigger('animation', [
      state('*', style({
        opacity: 1,
        transform: 'translateY(0px)'
      })),
      state('void', style({
        opacity: 0,
        transform: 'translateY(100px)'
      })),
      state('dismissed', style({
        opacity: 0,
        transform: 'translateY(100px)'
      })),
      transition(':enter', [animate('250ms ease-out')]),
      transition(':leave', [animate('250ms ease-in')]),
      transition('* => dismissed', [animate('250ms ease-in')]),
    ])
  ]
})
export class SnackbarContainerComponent implements Container<any, SnackBarOptions> {
  @Input()
  color: 'warn' | 'info' = 'info';

  @Output()
  onClose: EventEmitter<void>;

  @ViewChild(SnackbarDirective, { static: true })
  contentHost!: SnackbarDirective;

  state: string;

  constructor(private resolver: ComponentFactoryResolver) {
    this.onClose = new EventEmitter();
    this.state = 'created'
  }

  setContent<T>(content: Type<T>, options?: SnackBarOptions) {
    const factory = this.resolver.resolveComponentFactory(content);
    const comp = this.contentHost.viewContainerRef.createComponent<T>(factory);
    this.applyOptions(options);
    return comp;
  }

  onAnimationDone(event: AnimationEvent) {
    const DISMISSED = 'dismissed';
    if (this.state === DISMISSED && event.toState === DISMISSED) {
      this.onClose.emit();
    }
  }

  dismiss() {
    this.state = 'dismissed';
  }

  close(): void {
    this.onClose.emit();
  }

  private applyOptions(options: SnackBarOptions) {
    if (options?.dismissIn) {
      timer(options.dismissIn)
        .pipe(
          take(1)
        )
        .subscribe(() => {
          this.state = 'dismissed';
        })
    }
  }
}
