import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

import { EntityTypesComponent } from './entity-types/entity-types.component';
import { SelectorsTopComponent } from './selectors-top/selectors-top.component';
import { RelationTypesComponent } from './relation-types/relation-types.component';
import { ChipModule, ToggleSwitchModule } from 'elements-lib';

@NgModule({
  declarations: [
    EntityTypesComponent,
    SelectorsTopComponent,
    RelationTypesComponent
  ],
  imports: [
    CommonModule,
    ChipModule,
    ToggleSwitchModule,
    MatButtonModule,
  ],
  exports: [
    SelectorsTopComponent
  ]
})
export class SelectorsModule { }
