import { Overlay, OverlayPositionBuilder, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, InjectionToken, Injector, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { TooltipComponent } from './tooltip.component';

export const TOOLTIP_DATA = new InjectionToken<any>('TOOLTIP_DATA');

@Directive({
  selector: '[libTooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input() libTooltip: string | TemplateRef<void>;

  private overlayRef: OverlayRef | null = null;

  constructor(
    private element: ElementRef<HTMLElement>,
    private overlay: Overlay,
    private viewContainer: ViewContainerRef,
  ) {
  }

  @HostListener('mouseenter')
  @HostListener('focus')
  showTooltip(): void {
    if (this.overlayRef?.hasAttached() === true) {
      return;
    }
    this.attachTooltip();
  }

  @HostListener('mouseleave')
  @HostListener('blur')
  hideTooltip(): void {
    if (this.overlayRef?.hasAttached() === true) {
      this.overlayRef?.detach();
    }
  }

  ngOnDestroy(): void {
    this.overlayRef?.dispose();
  }

  private attachTooltip(): void {
    if (this.overlayRef === null) {
      const positionStrategy = this.getPositionStrategy();
      this.overlayRef = this.overlay.create({ positionStrategy });
    }

    const injector = Injector.create({
      providers: [
        {
          provide: TOOLTIP_DATA,
          useValue: this.libTooltip,
        },
      ],
    });
    const component = new ComponentPortal(TooltipComponent, this.viewContainer, injector);
    this.overlayRef.attach(component);
  }

  private getPositionStrategy(): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(this.element)
      .withPositions([
         {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
          panelClass: 'right',
        },
        {
          originX: 'start', 
          originY: 'center', 
          overlayX: 'end', 
          overlayY: 'center',
          panelClass: 'left',
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          panelClass: 'top',
        },
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          panelClass: 'bottom',
        },
      ]);
  }
}
