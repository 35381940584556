import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardModule } from '../card/card.module';
import { DialogComponent } from './dialog.component';
import { DialogDirective } from './dialog.directive';
import { DialogContainerComponent } from './dialog-container/dialog-container.component';

@NgModule({
  declarations: [
    DialogComponent,
    DialogDirective,
    DialogContainerComponent
  ],
  imports: [
    CommonModule,
    CardModule,
  ],
  exports:[
    DialogDirective,
    DialogComponent
  ]
})
export class DialogModule { }
