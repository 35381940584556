import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { SpanService } from '../../services/span.service';
import * as SpanActions from './span.actions';


@Injectable()
export class SpanEffects {

  clear$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('[Reports] Cancel Fetch Report'),
      tap(() => {
        this.spanService.reset();
      }),
      switchMap(() => of(SpanActions.clearSpans()))
    );
  });



  constructor(private actions$: Actions, private spanService: SpanService) { }

}
