import { createAction, props } from '@ngrx/store';

export const loadOntologies = createAction(
  '[Ontologies] Load Ontologies',
  props<{ ids: string[] }>()
);

export const selectOntology = createAction(
  '[Ontologies] Select Ontology',
  props<{ selected: string }>()
);

export const clearOntologies = createAction(
  '[Ontologies] Clear Ontologies',
);




