import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserService } from '../services/user.service';
import { UserEffects } from './user.effects';
import { aotState, userFeatureKey } from './user.reducer';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(userFeatureKey, aotState),
    EffectsModule.forFeature([UserEffects])
  ],
  providers: [
    UserService
  ]
})
export class UserStoreModule {}
