import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from 'projects/visual-client/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getAllOntologies } from '../../visualizer/store/ontologies/ontologies.reducer';

@Injectable({
  providedIn: 'root'
})
export class VisualizerGuard  {
  constructor(private store: Store, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(getAllOntologies)
      .pipe(
        map(ontologies => {
          const hasOntologies = ontologies.length > 0;
          if (!hasOntologies) {
            this.router.navigate([environment.homePath]);
          }
          return hasOntologies;
        })
      );
  }

}
