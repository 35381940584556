import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'lib-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent implements OnChanges {

  @Input('title')
  title: string;

  @Input('options')
  options: string[];

  @Output()
  onSelected: EventEmitter<string>;

  selectedOption: string;
  open = false;

  constructor() {
    this.onSelected = new EventEmitter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.options) {
      return;
    }
    const newOptions: string[] = changes.options.currentValue;
    if (newOptions && newOptions.includes(this.selectedOption)) {
      return;
    }
    this.selectedOption = null;
    this.onSelected.emit(this.selectedOption);

  }

  onChoose(event: Event) {
    this.selectedOption = (event.target as HTMLDListElement).textContent;
    this.onSelected.emit(this.selectedOption);
  }

}
