<div class="dropdown" (click)="open = !open" [ngClass]="{'open':open}">
    <div class="title" [ngClass]="{'empty': !selectedOption}">
        <div class="before"></div>
        <div class="text" *ngIf="!!selectedOption">{{title}}</div>
        <div class="after"></div>
    </div>
    <span class="value">{{selectedOption || title}}</span>
    <div class="arrow-icon">
        <span class="left-bar"></span>
        <span class="right-bar"></span>
    </div>
    <ul class="options">
        <li *ngFor="let option of options" class="option" (click)="onChoose($event)">{{option}}</li>
    </ul>
</div>