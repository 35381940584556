import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TextInputComponent } from 'elements-lib';
import { UserActions, UserSelectors, UserState } from 'pages-lib';
import { BehaviorSubject, combineLatest, fromEvent, Observable } from 'rxjs';
import { filter, map, takeWhile, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('button')
  signInButton: ElementRef<HTMLButtonElement>;

  @ViewChild('username')
  usernameInput: TextInputComponent;

  @ViewChild('password')
  passwordInput: TextInputComponent;

  @ViewChild('form')
  formElement: ElementRef<HTMLFormElement>;

  submitDisabled$: BehaviorSubject<boolean>;
  error$: BehaviorSubject<string>;
  alive: boolean;
  inProgress$: Observable<boolean>;

  constructor(private store: Store<UserState>) {
    this.alive = true;
    this.submitDisabled$ = new BehaviorSubject(true);
    this.error$ = new BehaviorSubject(null);
    this.inProgress$ = this.store.select(UserSelectors.selectInProgress);
  }


  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const formData$ = combineLatest([
      this.usernameInput.value$,
      this.passwordInput.value$
    ]);
    const signIn$ = fromEvent(this.signInButton.nativeElement, 'click');
    const submit$ = fromEvent<KeyboardEvent>(this.formElement.nativeElement, 'keyup')
      .pipe(
        filter(e => e.key === 'Enter' || e.code === 'Enter'),
      );

    this.store.select(UserSelectors.selectUserError)
      .pipe(
        map(error => {
          if (!error) {
            return null;
          }
          return error.error?.description || 'unknown error';
        }),
        tap(error => this.error$.next(error)),
        takeWhile(() => this.alive)
      ).subscribe();

    formData$.pipe(
      tap(() => this.error$.next(null)),
      takeWhile(() => this.alive)
    ).subscribe();

    combineLatest([
      this.usernameInput.empty$,
      this.passwordInput.empty$
    ]).pipe(
      tap(([noUser, noPass]) => {
        this.submitDisabled$.next(noUser || noPass);
      }),
      takeWhile(() => this.alive)
    ).subscribe();

    this.handleSignIn(signIn$, formData$);
    this.handleSignIn(submit$, formData$);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  private handleSignIn(signIn$: Observable<Event>, form$: Observable<[string, string]>) {
    signIn$.pipe(
      withLatestFrom(form$),
      tap(([clickEvent, [username, password]]) => {
        this.store.dispatch(UserActions.signIn({
          username, password
        }));
      }),
      takeWhile(() => this.alive)
    ).subscribe();
  }

}
