<lib-card-header>
    <h3>Sign In</h3>
    <button [disabled]="(submitDisabled$ | async) || (inProgress$ | async)" #button>Submit</button>
</lib-card-header>
<lib-card-content class="card-content" [ngClass]="{'hidden': inProgress$ | async}">
    <form id="sign-in-form" #form>
        <lib-text-input [for]="'username'" [label]="'Username'" tabindex="1" #username></lib-text-input>
        <lib-text-input [for]="'password'" [label]="'Password'" tabindex="2" [inputType]="'password'" #password>
        </lib-text-input>
    </form>
</lib-card-content>