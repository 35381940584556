import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UsernameTokenComponent } from './username-token.component';

@NgModule({
  declarations: [UsernameTokenComponent],
  imports: [
    CommonModule
  ],
  exports: [UsernameTokenComponent]
})
export class UsernameTokenModule { }
