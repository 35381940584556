<section #sectionElement [attr.data-label]="section.name">
    <!-- <h1>{{section.name}}</h1> -->
    @if (segments$ | async ; as segments) {
    <ng-template ngFor let-segment [ngForOf]="segments">
        <ng-container [ngSwitch]="segment.type">
            <ng-container *ngSwitchCase="'plain'">
                <span class="plain phi">{{segment.text}}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'new line'">
                <span class="new-line phi">{{segment.text}}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'entity'">
                <app-entity-group [segment]="segment"></app-entity-group>
            </ng-container>
        </ng-container>
    </ng-template>
    }@else{
    <span class="plain phi">{{section.text}}</span>
    }
</section>