import { AfterViewInit, Component, ComponentFactoryResolver, ElementRef, EventEmitter, Output, Renderer2, Type, ViewChild } from '@angular/core';
import { Container } from '../../core/component-Instantiator';
import { DialogDirective } from '../dialog.directive';
import { DialogOptions } from "../dialog.options";

@Component({
  selector: 'lib-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss']
})
export class DialogContainerComponent implements AfterViewInit, Container<any, DialogOptions> {
  @ViewChild(DialogDirective, { static: true })
  contentHost!: DialogDirective;

  @Output()
  onClose: EventEmitter<void>;

  @ViewChild('dialogContainer')
  container!: ElementRef<HTMLDivElement>;
  options: Partial<DialogOptions>;

  constructor(private resolver: ComponentFactoryResolver, private renderer: Renderer2) {
    this.onClose = new EventEmitter();
  }
  ngAfterViewInit(): void {
    this.applyOptions(this.options);
  }

  setContent<T>(content: Type<T>, options?: Partial<DialogOptions>) {
    this.options = options;
    return this.contentHost.viewContainerRef.createComponent<T>(content);
  }

  applyOptions(options: Partial<DialogOptions>) {
    if (options?.width) {
      this.renderer.setStyle(this.container.nativeElement, 'width', `${options.width}px`);
    }
    if (options?.height) {
      this.renderer.setStyle(this.container.nativeElement, 'height', `${options.height}px`);
    }
  }

  dismiss() {
    if (!this.options?.sticky) {
      this.close();
    }
  }

  close() {
    this.onClose.emit();
  }

}
