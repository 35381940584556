import { Component, ViewContainerRef } from '@angular/core';
import { SnackbarService } from './snackbar.service';

@Component({
  selector: 'lib-snackbar',
  template: '',
})
export class SnackbarComponent {

  constructor(private viewContainerRef: ViewContainerRef, private snackbar: SnackbarService) { }
  ngAfterViewInit(): void {
    this.snackbar.viewContainerRef = this.viewContainerRef;
  }

}
