import { Bounds } from "../section/section.component";

export interface SpanMap {
    [entityId: string]: Bounds;
}
export interface SpanChunk {
    id: string;
    ids: string[];
    bounds: Bounds;
    text?: string;
}

export class SpanResolver {
    private readonly ID_DELIMITER = ',';
    public flatten(spanMap: SpanMap): SpanChunk[] {
        if (!spanMap) {
            return [];
        }
        const ids = Object.keys(spanMap);
        const max = this.getMax(ids, spanMap);
        const spanChunks: SpanChunk[] = [];

        let pointer = 0;
        while (pointer <= max) {
            const spanStart = pointer;
            let spanEnd = spanStart;
            let scopeChunkIds = this.getIntersections(pointer, ids, spanMap);

            do {
                const chunkIds = this.getIntersections(pointer, ids, spanMap);
                if (!this.equalSets(chunkIds, scopeChunkIds)) {
                    break;
                }
                spanEnd = pointer;
                pointer++;
            } while (pointer <= max)
            const chunkIds = [...scopeChunkIds.values()].sort();
            const id = chunkIds.join(this.ID_DELIMITER);

            const spanChunk: SpanChunk = {
                id,
                ids: chunkIds,
                bounds: [spanStart, spanEnd]
            }
            spanChunks.push(spanChunk);
        }
        return spanChunks;
    }

    private getMax(ids: string[], spanMap: SpanMap) {
        return ids.reduce<number>((m, id) => {
            const [start, end] = spanMap[id];
            return Math.max(m, end);
        }, 0);
    }

    private getIntersections(index: number, ids: string[], spanMap: SpanMap) {
        const chunkIds = new Set<string>();
        ids.forEach(id => {
            const [start, end] = spanMap[id];
            if (index >= start && index <= end) {
                chunkIds.add(id);
            }
        });
        return chunkIds;
    }

    private equalSets<T>(a: Set<T>, b: Set<T>): boolean {
        if (a.size !== b.size) {
            return false;
        }
        for (const aItem of a) {
            if (!b.has(aItem)) {
                return false;
            }
        }
        return true;
    }
}
