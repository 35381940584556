import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetaComponent } from './meta.component';
import { EntityMetaComponent } from './entity-meta/entity-meta.component';



@NgModule({
  declarations: [MetaComponent, EntityMetaComponent],
  imports: [
    CommonModule
  ],
  exports: [
    MetaComponent
  ]
})
export class MetaModule { }
