import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarComponent } from './snackbar.component';
import { SnackbarContainerComponent } from './snackbar-container/snackbar-container.component';
import { SnackbarDirective } from './snackbar.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    SnackbarComponent, 
    SnackbarContainerComponent, 
    SnackbarDirective
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule
  ],
  exports: [
    SnackbarComponent
  ]
})
export class SnackbarModule { }
