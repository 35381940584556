<lib-dialog></lib-dialog>
<lib-snackbar></lib-snackbar>
<lib-header [homepage]="'/text-entry'">
    <div logo>
        <svg xmlns="http://www.w3.org/2000/svg" width="170" height="18" viewBox="0 0 782.52 97.74">
            <defs>
                <style>
                    .cls-1 {
                        fill: #fff;
                    }

                    .cls-2 {
                        fill: #3fb6e8;
                    }
                </style>
            </defs>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_3" data-name="Layer 3">
                    <path class="cls-1"
                        d="M162.14,36.53V23.35h12.8v-18h15.51v18h17.07V36.53H190.45V74.46c0,6.21,1.81,10.09,8.67,10.09,2.71,0,6.46-.52,8.4-1.94V95.68c-3.23,1.54-8.66,2.06-12.28,2.06-16.42,0-20.3-7.37-20.3-22V36.53Z" />
                    <path class="cls-1"
                        d="M226.29,70.59c1,9.18,8,14.73,16.68,14.73,7.76,0,12.8-3.61,16.68-8.41l11.12,8.41a32.36,32.36,0,0,1-26.25,12.42c-18.75,0-33.74-13.06-33.74-32.58s15-32.59,33.74-32.59c17.32,0,29.1,12.15,29.1,33.75v4.27ZM258.1,59C258,49.9,252,44.22,242.32,44.22c-9.18,0-14.87,5.81-16,14.73Z" />
                    <path class="cls-1" d="M282.56,0h15.52V97.74H282.56Z" />
                    <path class="cls-1" d="M310.38,0h15.51V97.74H310.38Z" />
                    <path class="cls-1" d="M338.19,35.69h15.52V97.74H338.19Z" />
                    <path class="cls-2"
                        d="M139.44,34.13c-9.31,0-15.91,3.62-20.43,11.51-3.23-7.76-9.82-11.51-19.39-11.51-10.35,0-16.29,5.43-19,11.24h-.25V35.69H65.61V70.5L52.7,68.21c-1.28,6-4.14,11.55-11.06,15.06-7.72,3.94-16.47,2.15-21.56-5.56L62.27,56.22l-1.93-3.81C50.53,33.15,34.52,27.66,19.07,35.52,2.35,44.05-5.1,62.5,3.77,79.91S32,102.16,48.66,93.64a32.3,32.3,0,0,0,17-20.52V97.74H81.13V63.87c0-8.28,4-15.78,14.22-15.78,7.5,0,10.6,5,10.6,12.67v37h15.52V64.13c0-8,3.62-16,13.31-16,9.18,0,11.51,6.73,11.51,14.48V97.74h15.52v-37C161.81,45.9,156,34.13,139.44,34.13Zm-117,12.78c8.65-4.41,16.54-2,20.77,6L14.79,67.33C11.77,58.85,14.21,51.08,22.39,46.91Z" />
                    <path class="cls-2"
                        d="M345.82,5.31c5.69,0,10.08,4,10.08,9.3s-4.39,9.31-10.08,9.31A9.55,9.55,0,0,1,336,14.61,9.55,9.55,0,0,1,345.82,5.31Z" />
                    <path class="cls-1"
                        d="M542.69,10.4h-5V6.71h14.16V10.4h-5V23.57h-4.17Zm13-3.69h5.81l4,11,4-11h5.81V23.57h-4.17v-11h-.09l-4.13,11H564.1l-4.14-11h-.08v11h-4.17Z" />
                    <path class="cls-1"
                        d="M365.1,6.29h30.74c17.7,0,33.72,6.2,33.72,26.48,0,23-17.18,27.12-36.56,27.12H381.38V97.74H365.1Zm26.09,39.65c9.3,0,21.32-.51,21.32-12.91,0-11.24-10.72-12.79-19.51-12.79H381.38v25.7Z" />
                    <path class="cls-1"
                        d="M435.92,35.74h15.5v9.82h.26A19.73,19.73,0,0,1,470,34.19a20.14,20.14,0,0,1,5.55.9v15a30.82,30.82,0,0,0-7.49-1.17c-14.59,0-16.66,12.27-16.66,15.63v33.2h-15.5Z" />
                    <path class="cls-1"
                        d="M511.76,32.57c18.73,0,33.72,13,33.72,32.55s-15,32.55-33.72,32.55-33.71-13-33.71-32.55S493,32.57,511.76,32.57Zm0,51.15c11.37,0,18.22-8.91,18.22-18.6s-6.85-18.6-18.22-18.6-18.21,8.92-18.21,18.6S500.4,83.72,511.76,83.72Z" />
                    <rect class="cls-2" x="594.25" y="0.07" width="188.27" height="97.67" />
                    <path class="cls-1"
                        d="M619.19,11.52,626,29.82a36.88,36.88,0,0,1,1.45,5.67,43,43,0,0,1,1.64-5.7l7-18.27h5.86L629.6,42.08H625L613,11.52Z" />
                    <path class="cls-1" d="M650.44,11.52V42.08h-5.51V11.52Z" />
                    <path class="cls-1"
                        d="M675.93,14,672,18a5.28,5.28,0,0,0-2.24-1.81,7.31,7.31,0,0,0-3-.6,5.81,5.81,0,0,0-3.89,1.17,3.62,3.62,0,0,0-1.36,2.84,3.28,3.28,0,0,0,.57,1.9A4.81,4.81,0,0,0,663.92,23c.63.29,2,.76,4,1.42A24.8,24.8,0,0,1,672.11,26a8.27,8.27,0,0,1,3,2.86,8,8,0,0,1,1.09,4.29,8.92,8.92,0,0,1-3,6.89c-2,1.82-4.76,2.72-8.38,2.72a14,14,0,0,1-5.74-1.1A11.85,11.85,0,0,1,655,38.74l4.08-4a7.33,7.33,0,0,0,2.71,2.38,7.51,7.51,0,0,0,3.4.79,5.73,5.73,0,0,0,3.9-1.26,3.83,3.83,0,0,0,1.43-2.94,3.5,3.5,0,0,0-.59-2,4,4,0,0,0-1.61-1.38c-.42-.2-1.62-.63-3.59-1.3s-3.28-1.16-3.91-1.45a9.7,9.7,0,0,1-2.74-1.88,7.7,7.7,0,0,1-1.62-2.5,8.4,8.4,0,0,1-.57-3.12A8.47,8.47,0,0,1,658.25,14q3.09-3.27,8.8-3.27a13.66,13.66,0,0,1,5.05.85A11.91,11.91,0,0,1,675.93,14Z" />
                    <path class="cls-1"
                        d="M687.15,11.52V28.74a21.11,21.11,0,0,0,.19,3.47A7.25,7.25,0,0,0,688.5,35a6,6,0,0,0,2.4,2,7.14,7.14,0,0,0,3.13.72,7,7,0,0,0,3-.66,6,6,0,0,0,2.33-1.89,7,7,0,0,0,1.23-2.63,18.28,18.28,0,0,0,.25-3.66V11.52h5.38V27.8a39.3,39.3,0,0,1-.19,4.54,13.43,13.43,0,0,1-1.25,4.38,10.78,10.78,0,0,1-6.1,5.39,14.66,14.66,0,0,1-4.85.7,13.75,13.75,0,0,1-5.35-1,10.34,10.34,0,0,1-4-2.94,11.62,11.62,0,0,1-2.24-4.27,25.39,25.39,0,0,1-.65-6.49V11.52Z" />
                    <path class="cls-1"
                        d="M718.21,35.11l-2.78,7h-6l13.27-30.56h4.62l13.13,30.56h-6.2l-2.83-7ZM720,30.42h9.48l-3.3-8.23c-.52-1.3-1-2.69-1.43-4.16a40.28,40.28,0,0,1-1.47,4.31Z" />
                    <path class="cls-1" d="M749.44,11.52V37.17h13v4.91H743.85V11.52Z" />
                    <path class="cls-1"
                        d="M641.24,56.27l-4.41,3.63A7.64,7.64,0,0,0,634,57.75a8.8,8.8,0,0,0-3.63-.75,9.93,9.93,0,0,0-5,1.37,9.39,9.39,0,0,0-3.69,3.9,12,12,0,0,0-1.33,5.65,11.38,11.38,0,0,0,1.28,5.44,9.06,9.06,0,0,0,3.69,3.72,10.37,10.37,0,0,0,5.07,1.32,7.93,7.93,0,0,0,6.89-3.55L642,78.28a12.6,12.6,0,0,1-4.61,3.87,14.65,14.65,0,0,1-6.71,1.46,15.75,15.75,0,0,1-9-2.45,15.42,15.42,0,0,1-5.51-6.41,16.55,16.55,0,0,1-1.57-7.14,16.07,16.07,0,0,1,2.11-8.17,14.87,14.87,0,0,1,5.65-5.67,16.2,16.2,0,0,1,8.09-2,15.91,15.91,0,0,1,5,.74,13.75,13.75,0,0,1,3.72,1.85A10.32,10.32,0,0,1,641.24,56.27Z" />
                    <path class="cls-1" d="M651.47,52.38V78h13v4.91H645.88V52.38Z" />
                    <path class="cls-1" d="M673.54,52.38V82.94H668V52.38Z" />
                    <path class="cls-1"
                        d="M680.15,52.38h20.19V57.3H685.53v7.56h13.82v4.89H685.53V78h15.56v4.91H680.15Z" />
                    <path class="cls-1"
                        d="M713.63,52.38l12.22,18.8A31,31,0,0,1,728.73,77q-.26-3.63-.25-6.25V52.38h5.35V82.94H726.9l-12-18.5c-.51-.77-1.1-1.79-1.79-3.07-.43-.84-.91-1.91-1.43-3.21.12,1.37.17,3.39.17,6.07V82.94h-5.35V52.38Z" />
                    <path class="cls-1" d="M761.6,52.38V57.3h-9.27V82.94h-5.48V57.3H737.7V52.38Z" />
                </g>
            </g>
        </svg>
    </div>
    <!-- <span class="sub-brand">Visual Client <span class="release beta">beta</span></span> -->
    <div class="flex flex-row gap-4 justify-end ml-auto">
        <lib-username-token [username]="username$ | async" [userToolTip]="server$ | async"
            [loggedIn]="loggedIn$ | async" (onLogout)="onLogout()"></lib-username-token>
    </div>
</lib-header>
<div class="page">
    <section class="content">
        <router-outlet></router-outlet>
    </section>
    <div class="footer">
        <small>
            ©{{year}} by emtelligent.
        </small>
        <small>
            v{{timeStamp}}-{{commitId.substr(0, 6)}}
        </small>
    </div>
</div>