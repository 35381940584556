import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";

interface State {
    checked: boolean;
}

export const defaultState: State = {
    checked: false
}

@Injectable()
export class ToggleSwitchState extends ComponentStore<State> {

    checked$ = this.select(state => state.checked);
    updateChecked = this.updater((state, checked: boolean) => {
        return {
            ...state,
            checked
        }
    })

    constructor() {
        super(defaultState);
    }
}