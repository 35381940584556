import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';

import { concatMap, switchMap, tap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import * as AppStateActions from './app-state.actions';
import { Action } from '@ngrx/store';
import { ConfigService } from '../config.service';


@Injectable()
export class AppStateEffects implements OnInitEffects {
  fetchConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppStateActions.fetchAppConfig),//, '[Reports] Cancel Fetch Report'),
      switchMap(() => {
        return this.config.fetchConfig()
          .pipe(
            switchMap((appConfig) => of(AppStateActions.fetchAppConfigSuccess({
              emtelliproServer: appConfig.emtelliproServer,
              iamServer: appConfig.iamServer,
              allowedExtensions: appConfig.allowedExtensions,
              enabledFeatures: appConfig.enabledFeatures
            })))
          );
      })
    );
  });

  constructor(private actions$: Actions, private config: ConfigService) { }
  ngrxOnInitEffects(): Action {
    return AppStateActions.fetchAppConfig()
  }

}
