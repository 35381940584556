import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Section } from './section.model';

export const loadSections = createAction(
  '[Section/API] Load Sections', 
  props<{ sections: Section[] }>()
);

export const addSection = createAction(
  '[Section/API] Add Section',
  props<{ section: Section }>()
);

export const upsertSection = createAction(
  '[Section/API] Upsert Section',
  props<{ section: Section }>()
);

export const addSections = createAction(
  '[Section/API] Add Sections',
  props<{ sections: Section[] }>()
);

export const upsertSections = createAction(
  '[Section/API] Upsert Sections',
  props<{ sections: Section[] }>()
);

export const updateSection = createAction(
  '[Section/API] Update Section',
  props<{ section: Update<Section> }>()
);

export const updateSections = createAction(
  '[Section/API] Update Sections',
  props<{ sections: Update<Section>[] }>()
);

export const deleteSection = createAction(
  '[Section/API] Delete Section',
  props<{ id: string }>()
);

export const deleteSections = createAction(
  '[Section/API] Delete Sections',
  props<{ ids: string[] }>()
);

export const clearSections = createAction(
  '[Section/API] Clear Sections'
);
