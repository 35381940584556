import { createReducer, on } from '@ngrx/store';
import * as AppStateActions from './app-state.actions';
import type { AdvancedOptionsState } from '../advanced-options.type';

export const appStateFeatureKey = 'appState';

export interface State {
  iamServer: string;
  emtelliproServer: string;
  serverOverride?: string;
  allowedExtensions: string[];
  enabledFeatures: string[];
  advancedOptions: AdvancedOptionsState;
}

export const initialState: State = {
  emtelliproServer: null,
  iamServer: null,
  allowedExtensions: [],
  enabledFeatures: [],
  advancedOptions: null,
};

export const reducer = createReducer(
  initialState,

  on(AppStateActions.loadAppStates, (state) => state),
  on(
    AppStateActions.fetchAppConfigSuccess,
    (
      state,
      { emtelliproServer, iamServer, allowedExtensions, enabledFeatures }
    ) => {
      return {
        ...state,
        emtelliproServer: emtelliproServer || state.emtelliproServer,
        serverOverride: null,
        iamServer: iamServer || state.iamServer,
        allowedExtensions,
        enabledFeatures,
      };
    }
  ),
  on(AppStateActions.setServerOverride, (state, { url }) => {
    return {
      ...state,
      serverOverride: url,
    };
  }),
  on(AppStateActions.saveAdvancedFeatures, (state, { advancedOptions }) => ({
    ...state,
    advancedOptions,
  }))
);
