<div class="header">
    <a [routerLink]="[homepage]">
        <div #logo>
            <ng-content select="[logo]"></ng-content>
        </div>
        <ng-container *ngIf="!logo.children.length">
            <svg class="logo" xmlns="http://www.w3.org/2000/svg" width="110" height="21.27" viewBox="0 0 110 21.27">
                <defs>
                    <style>
                        .a {
                            fill: #fff;
                        }

                        .b {
                            fill: #20a9e3;
                        }
                    </style>
                </defs>
                <g transform="translate(51 -73)">
                    <g transform="translate(-51 73)">
                        <path class="a"
                            d="M243.9,405.815v-2.2h2.137v-3h2.591v3h2.849v2.2H248.63v6.334c0,1.037.3,1.684,1.446,1.684a2.7,2.7,0,0,0,1.4-.323v2.182a5.479,5.479,0,0,1-2.05.344c-2.742,0-3.39-1.231-3.39-3.67v-6.55Z"
                            transform="translate(-216.979 -399.715)" />
                        <path class="a"
                            d="M295.215,434.212A2.685,2.685,0,0,0,298,436.672a3.4,3.4,0,0,0,2.784-1.4l1.858,1.4a5.542,5.542,0,1,1-4.383-8.808c2.892,0,4.858,2.029,4.858,5.636v.712Zm5.311-1.943a2.384,2.384,0,0,0-2.634-2.461,2.576,2.576,0,0,0-2.677,2.461Z"
                            transform="translate(-257.631 -422.482)" />
                        <path class="a" d="M364.539,395.233h2.591v16.32h-2.591Z"
                            transform="translate(-317.607 -395.233)" />
                        <path class="a" d="M392.4,395.233h2.591v16.32H392.4Z"
                            transform="translate(-340.849 -395.233)" />
                        <path class="a" d="M420.264,430.985h2.591v10.361h-2.591Z"
                            transform="translate(-364.093 -425.086)" />
                        <path class="a"
                            d="M455.944,428.957v9.432c0,3.736-1.812,6.111-5.85,6.111a7.379,7.379,0,0,1-5.136-1.705l1.554-2.116a5,5,0,0,0,3.561,1.491c2.5,0,3.282-1.426,3.282-3.477v-.756h-.044a4.215,4.215,0,0,1-3.389,1.511,5.076,5.076,0,0,1-5.159-5.31c0-3.066,1.921-5.441,5.117-5.441a4,4,0,0,1,3.561,1.814h.042v-1.554Zm-8.592,5.157a2.993,2.993,0,0,0,3.045,3,3.045,3.045,0,1,0-3.045-3Z"
                            transform="translate(-384.557 -423.23)" />
                        <path class="a"
                            d="M523.157,434.212a2.686,2.686,0,0,0,2.786,2.461,3.4,3.4,0,0,0,2.784-1.4l1.858,1.4a5.542,5.542,0,1,1-4.384-8.808c2.893,0,4.859,2.029,4.859,5.636v.712Zm5.312-1.943a2.384,2.384,0,0,0-2.633-2.461,2.578,2.578,0,0,0-2.679,2.461Z"
                            transform="translate(-447.797 -422.482)" />
                        <path class="a"
                            d="M592.228,429.685h2.461v1.661h.044a3.249,3.249,0,0,1,3.173-1.921c2.784,0,3.779,1.965,3.779,4.081v6.539h-2.591V434.8c0-1.145-.088-3.045-1.9-3.045-1.707,0-2.375,1.253-2.375,2.635v5.655h-2.591Z"
                            transform="translate(-507.667 -423.784)" />
                        <path class="a"
                            d="M653.522,405.815v-2.2h2.138v-3h2.591v3H661.1v2.2h-2.849v6.334c0,1.037.3,1.684,1.447,1.684a2.7,2.7,0,0,0,1.4-.323v2.182a5.484,5.484,0,0,1-2.051.344c-2.742,0-3.389-1.231-3.389-3.67v-6.55Z"
                            transform="translate(-558.744 -399.715)" />
                        <path class="b"
                            d="M104.759,427.7a3.606,3.606,0,0,0-3.411,1.921,3.162,3.162,0,0,0-3.239-1.921,3.294,3.294,0,0,0-3.173,1.877h-.043v-1.617H92.431v5.813l-2.156-.384a3.4,3.4,0,0,1-1.847,2.516,2.687,2.687,0,0,1-3.6-.928l7.046-3.59-.324-.636c-1.637-3.215-4.311-4.131-6.891-2.82a5.446,5.446,0,1,0,7.772,6.279v4.11h2.591v-5.655c0-1.381.669-2.635,2.375-2.635,1.251,0,1.77.843,1.77,2.116v6.174h2.59v-5.611c0-1.339.6-2.679,2.224-2.679,1.533,0,1.921,1.123,1.921,2.419v5.871h2.591v-6.174C108.493,429.661,107.522,427.7,104.759,427.7ZM85.214,429.83a2.385,2.385,0,0,1,3.467,1l-4.737,2.412A2.578,2.578,0,0,1,85.214,429.83Z"
                            transform="translate(-81.475 -422.054)" />
                        <path class="b"
                            d="M419.71,400.548a1.556,1.556,0,1,1-1.64,1.554A1.592,1.592,0,0,1,419.71,400.548Z"
                            transform="translate(-362.265 -399.662)" />
                        <g transform="translate(103.713 1.121)">
                            <path class="a"
                                d="M707.957,402.572h-.834v-.615h2.365v.615h-.834v2.2h-.7Zm2.173-.615h.97l.67,1.832.67-1.832h.971v2.816h-.7v-1.831H712.7l-.69,1.831h-.478l-.69-1.831h-.014v1.831h-.7Z"
                                transform="translate(-707.123 -401.957)" />
                        </g>
                    </g>
                </g>
            </svg>
        </ng-container>

    </a>
    <ng-content></ng-content>
</div>