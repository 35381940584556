import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule, CardModule, TextInputModule } from 'elements-lib';
import { SessionExpiredComponent } from './session-expired/session-expired.component';



@NgModule({
  declarations: [SessionExpiredComponent],
  imports: [
    CommonModule,
    ButtonModule,
    TextInputModule,
    CardModule
  ]
})
export class PromptsModule { }
