import { createAction, props } from '@ngrx/store';
import { GetUserStatusSuccess, GetUserSuccess, SignInSuccess } from '../services/user.api.model';
import { UserAccountStatus } from './user.reducer';

export const loadUser = createAction(
  '[User] Load User',
  props<{ username: string }>()
);

export const userLoaded = createAction(
  '[User] User Loaded',
  props<GetUserSuccess>()
);

export const loadUserSuccess = createAction(
  '[User] Load User Success',
  props<{ data: any }>()
);

export const loadUserFailure = createAction(
  '[User] Load User Failure',
  props<{ error: any }>()
);

export const noLocalUser = createAction(
  '[User] No Local User'
);

export const signIn = createAction(
  '[User] Sign In',
  props<{ username: string; password: string; }>()
);

export const signInSuccess = createAction(
  '[User] Sign In Success',
  props<SignInSuccess>()
);

export const signInFail = createAction(
  '[User] Sign In Fail',
  props<{ username: string, error?: unknown }>()
);

export const changePassword = createAction(
  '[User] Change Password',
  props<{ username: string, password: string, newPassword: string }>()
);

export const changePasswordSuccess = createAction(
  '[User] Change Password Success',
  props<{ username: string, password: string }>()
);

export const changePasswordError = createAction(
  '[User] Change Password Error',
  props<{ error?: unknown }>()
);

export const signup = createAction(
  '[User] Signup',
  props<{
    email: string;
    password: string;
    eula: string;
    firstName: string;
    lastName: string;
    companyName: string;
    jobTitle: string;
  }>()
);

export const signupSuccess = createAction(
  '[User] Signup Success',
  props<{
    email: string;
    password: string;
    eula: string;
    firstName: string;
    lastName: string;
    companyName: string;
    jobTitle: string;
  }>()
);

export const signupFail = createAction(
  '[User] Signup Fail',
  props<{ error?: unknown }>()
);

export const sessionExpired = createAction(
  '[User] Session Expired'
);

export const signOut = createAction(
  '[User] Sign Out',
  props<{ error?: unknown }>()
);

export const setEULA = createAction(
  '[User] Set EULA',
  props<{ eula: string }>()
);

export const sendForgotPasswordEmail = createAction(
  '[User] Send Forgot Password Email',
  props<{ email: string }>()
);

export const sendForgotPasswordEmailSuccess = createAction(
  '[User] Send Forgot Password Email Success'
);

export const sendForgotPasswordEmailFail = createAction(
  '[User] Send Forgot Password Email Fail',
  props<{ error: unknown }>()
);

export const sendVerificationEmail = createAction(
  '[User] Send Verification Email'
);

export const sendVerificationEmailSuccess = createAction(
  '[User] Send Verification Email Success'
);

export const sendVerificationEmailFail = createAction(
  '[User] Send Verification Email Fail',
  props<{ error: unknown }>()
);

export const verifyEmail = createAction(
  '[User] Verify Email',
  props<{ email: string, verificationCode: string }>()
);

export const verifyEmailSuccess = createAction(
  '[User] Verify Email Success'
);

export const verifyEmailFail = createAction(
  '[User] Verify Email Fail',
  props<{ error: unknown }>()
);

export const getUserStatus = createAction(
  '[User] Get User Status'
);

export const getUserStatusSuccess = createAction(
  '[User] Get User Status Success',
  props<GetUserStatusSuccess>()
);

export const userAccountStatusSuccess = createAction(
  '[User] User Account Status Success',
  props<UserAccountStatus>()
);

export const userAccountStatusFail = createAction(
  '[User] User Account Status Fail',
  props<UserAccountStatus>()
);

export const getUserStatusFail = createAction(
  '[User] Get User Status Fail',
  props<{ error: unknown }>()
);

export const acceptEula = createAction(
  '[User] Accept EULA',
  props<{ eula: string }>()
);

export const acceptEulaSuccess = createAction(
  '[User] Accept EULA Success'
);

export const acceptEulaFail = createAction(
  '[User] Accept EULA Fail',
  props<{ error: unknown }>()
);

export const clearError = createAction(
  '[User] Clear Error'
);

export const getQuotas = createAction(
  '[User] Get Quotas'
)

export const getQuotasFailure = createAction(
  '[User] Get Quotas Fail',
  props<{ error: unknown }>()
)

export const getQuotasSuccess = createAction(
  '[User] Get Quotas Success',
   props<{ reports_processed: number, reports_quota: number}>()
)

