import { AfterViewInit, Component, ComponentFactoryResolver, Inject, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, takeWhile, tap } from 'rxjs/operators';
import { ComponentType } from '@angular/cdk/overlay';
import { AuthOutletDirective } from '../auth-outlet.directive';
import { AuthStep, UserSelectors, UserState } from 'pages-lib';

export interface AuthPages<T, U> {
  Login: ComponentType<T>,
  ChangePassword: ComponentType<U>
}

@Component({
  selector: 'lib-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, AfterViewInit, OnDestroy {
  inProgress$: Observable<boolean>;
  error$: BehaviorSubject<string>;
  step$: Observable<AuthStep>;
  alive: boolean;


  @ViewChild(AuthOutletDirective, { static: true })
  outlet!: AuthOutletDirective;


  constructor(
    private store: Store<UserState>,
    private resolver: ComponentFactoryResolver,
    @Inject('AuthPages') private authPages: AuthPages<any, any>) {
    this.alive = true;
    this.inProgress$ = this.store.select(UserSelectors.selectInProgress);
    this.step$ = this.store.select(UserSelectors.selectAuthStep);
    this.error$ = new BehaviorSubject(null);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  ngAfterViewInit(): void {
    this.store.select(UserSelectors.selectUserError)
      .pipe(
        map(error => {
          if (!error) {
            return null;
          }
          if (error.status === 0 && error.headers && Object.keys(error.headers.headers).length === 0) {
            const url = new URL(error.url);
            return `${url.host} is unreachable.`;
          }
          return error.error?.description || error.message || 'unknown error';
        }),
        tap(error => this.error$.next(error)),
        takeWhile(() => this.alive)
      ).subscribe();
  }

  ngOnInit(): void {

    this.step$.pipe(
      takeWhile(() => this.alive)
    ).subscribe((step) => {
      switch (step) {
        case AuthStep.Unauthenticated:
          this.setContent(this.authPages.Login);
          break;
        case AuthStep.ChangePassword:
          this.setContent(this.authPages.ChangePassword);
        default:
          break;
      }
    })

  }

  setContent<T>(content: Type<T>) {
    const factory = this.resolver.resolveComponentFactory(content);
    const ref = this.outlet.viewContainerRef;
    ref.clear();
    return ref.createComponent<T>(factory);
  }

}
