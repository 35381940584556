import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { concatMap, switchMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import * as VisualizerActions from './visualizer.actions';
import { clearSpans } from '../span/span.actions';


@Injectable()
export class VisualizerEffects {


  // loadVisualizers$ = createEffect(() => {
  //   return this.actions$.pipe( 

  //     ofType(VisualizerActions.loadVisualizers),
  //     /** An EMPTY observable only emits completion. Replace with your own observable API request */
  //     concatMap(() => EMPTY)
  //   );
  // });

  clear$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('[Reports] Cancel Fetch Report'),
      switchMap(() => of(clearSpans()))
    );
  });


  constructor(private actions$: Actions) {}

}
