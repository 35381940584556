import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { takeWhile, tap, withLatestFrom } from 'rxjs/operators';
import { defaultState, ToggleSwitchState } from './toggle-switch.state';

export interface ToggleEvent {
  value: boolean;
  fromClick: boolean;
}

@Component({
  selector: 'lib-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  providers: [
    ToggleSwitchState
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleSwitchComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input('direction') direction: 'left' | 'right';
  @Input('value') value$: Observable<boolean>;
  @Output('toggle') onChange: EventEmitter<ToggleEvent>;
  @ViewChild('toggle') element: ElementRef<HTMLInputElement>;

  clicked$: Observable<Event>;
  initialCheckedValue: boolean;

  get checked$() {
    return this.state.checked$;
  }

  private isAlive: boolean;

  constructor(private state: ToggleSwitchState) {
    this.isAlive = true;
    this.initialCheckedValue = defaultState.checked;
    this.onChange = new EventEmitter();
  }

  ngOnInit(): void {
    this.state.updateChecked(this.initialCheckedValue);
    this.checked$.pipe(
      takeWhile(() => this.isAlive)
    ).subscribe(checked => {
      if (this.element) {
        this.element.nativeElement.checked = checked;
      }
      this.onChange.emit({
        value: checked,
        fromClick: false
      });
    })
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  ngAfterViewInit(): void {
    this.clicked$ = fromEvent(this.element.nativeElement, 'click');
    fromEvent(this.element.nativeElement, 'change').pipe(
      takeWhile(() => this.isAlive)
    ).subscribe(event => {
      const checked = this.element.nativeElement.checked;
      this.state.updateChecked(checked);
    });


    this.clicked$.pipe(
      withLatestFrom(this.checked$),
      tap(([event, checked]) => {
        this.onChange.emit({
          value: !checked,
          fromClick: true
        });
      }),
      takeWhile(() => this.isAlive)
    ).subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value$ && changes.value$.firstChange) {
      (changes.value$.currentValue as Observable<boolean>)
        .pipe(
          takeWhile(() => this.isAlive)
        ).subscribe(inputValue => {
          this.initialCheckedValue = inputValue;
          this.state.updateChecked(inputValue);
        });
    }
  }
}
