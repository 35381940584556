<lib-card class="card">
    <ng-template libAuthOutlet></ng-template>
    <div class="progress-line" *ngIf="inProgress$ | async"></div>
    <ng-container *ngIf="(step$ | async) !== '[Unauthenticated]'">
        <lib-card-content *ngIf="(error$ | async) as error">
            <p class="error">{{error}}</p>
        </lib-card-content>
    </ng-container>
    <lib-card-footer>
        <small>unauthorized access prohibited</small>
    </lib-card-footer>
</lib-card>