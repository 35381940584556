import { Action, createReducer, on } from '@ngrx/store';
import * as VisualizerActions from './visualizer.actions';

export const visualizerFeatureKey = 'visualizer-state';
export enum DisplayState {
  HIDDEN = 'hidden',
  VISIBLE = 'visible'
}

export interface State {
  hydratedStores: string[];
  ontologiesSelector: DisplayState;
  entityTypesSelector: DisplayState;
  relationTypesSelector: DisplayState
}

export const initialState: State = {
  hydratedStores: [],
  ontologiesSelector: DisplayState.VISIBLE,
  entityTypesSelector: DisplayState.VISIBLE,
  relationTypesSelector: DisplayState.VISIBLE
};


export const reducer = createReducer(
  initialState,

  on(VisualizerActions.storeHydrated, (state, { storeType: partType }) => {
    const hydratedStores = [...state.hydratedStores];
    hydratedStores
      .push(partType);
    hydratedStores.sort();
    return {
      ...state,
      hydratedStores
    }
  }),
  on(VisualizerActions.clearHydratedStores, (state) => {
    return {
      ...state,
      hydratedStores: []
    }
  }),
  on(VisualizerActions.setOntologiesSelectorDisplay, (state, { display }) => ({
    ...state,
    ontologiesSelector: display
  })),
  on(VisualizerActions.setEntityTypesSelectorDisplay, (state, { display }) => ({
    ...state,
    entityTypesSelector: display
  })),
  on(VisualizerActions.setRelationTypesSelectorDisplay, (state, { display }) => ({
    ...state,
    relationTypesSelector: display
  }))
);

