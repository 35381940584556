import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import IntervalTree from '@flatten-js/interval-tree';
import { select, Store } from '@ngrx/store';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, takeWhile, tap, withLatestFrom } from 'rxjs/operators';
import { setHighlightedSpans } from '../store/entity/entity.actions';
import { Entity } from '../store/entity/entity.model';
import * as EntityReducers from '../store/entity/entity.reducer';
import { State } from '../store/entity/entity.reducer';
import { Section } from '../store/section/section.model';
import { selectSourceString } from 'pages-lib';

export type Bounds = [number, number];

export interface Segment {
  text: string;
  type: EntityType;
  nested?: {
    [id: string]: Bounds
  };
  id?: string;
  ids?: string[];
}

enum EntityType {
  ENTITY = 'entity',
  PLAIN = 'plain',
  NEW_LINE = 'new line',
  PLAIN_NEW_LINE = 'plain new line'
}

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  section: Section;

  @ViewChild('sectionElement')
  sectionElement: ElementRef;

  entities$: Observable<Entity[]>;
  segments$: Observable<Segment[]>;

  private alive = true;
  private sourceText$: Observable<string>;

  constructor(private store: Store<State>) {
    this.sourceText$ = this.store.select(selectSourceString);
  }

  ngOnInit(): void {
    this.filterEntities();
    this.buildSpans();
  }

  private filterEntities() {
    this.entities$ = this.store
      .pipe(
        select(EntityReducers.selectAllEntities),
        distinctUntilChanged((x, y) => {
          return JSON.stringify(x) === JSON.stringify(y);
        })
      );
  }

  private buildSpans() {
    this.segments$ = combineLatest([
      this.entities$,
      this.sourceText$
    ])
      .pipe(
        filter(([allEntities, source]) => allEntities.length > 0 && !!source),
        map(([allEntities, source]) => {
          const entities = allEntities.filter(entity => {
            return this.intersectingArrays(entity.locations, [this.section.id, ...this.section.subSectionIds])
          });
          return this.splitSegments(entities, source);
        })
      );
  }

  private splitSegments(entities: Entity[], source: string) {
    const sectionStart = this.section.spans[0].start;
    const sectionEnd = this.section.spans[0].end;
    const entityMap = new Map<string, [number, number]>();

    const tree = this.populateIntervalTree(entities, entityMap);

    let lastIntersections: string[] = [];
    const acmIntersections = new Set<string>();
    const segments: Segment[] = [];

    let whiteSpaces: string[] = [];
    let spanStart = sectionStart;

    for (let i = sectionStart; i <= sectionEnd; i++) {
      const nextIntersections = tree.search([i, i]) as string[];
      const willChangeContext = i === sectionEnd
        || (
          nextIntersections.length !== lastIntersections.length
          && !this.intersectingArrays(nextIntersections, lastIntersections)
        );

      if (!willChangeContext) {
        lastIntersections.forEach(intersection => {
          acmIntersections.add(intersection);
        });
      }

      if (willChangeContext || i === sectionEnd) {

        const isPlainText = lastIntersections.length === 0;
        const type = isPlainText ? EntityType.PLAIN : EntityType.ENTITY;
        const text = source.substring(spanStart, i);

        const segment: Segment = {
          text,
          type
        };

        if (type === EntityType.ENTITY) {
          let trimText = text.replace(/\n/, ' ');
          let lastChar = trimText.substr(trimText.length - 1);
          const intersections = acmIntersections.size > 0 ? [...acmIntersections.values()] : [...lastIntersections.values()];
          const nestedSpans = this.getNestedSpans(intersections, entityMap, spanStart);

          while (lastChar === '\n') {
            whiteSpaces.push(lastChar);
            trimText = trimText.substring(0, trimText.length - 1);
            lastChar = trimText.substr(trimText.length - 1);
          }
          segment.text = trimText;
          segment.id = lastIntersections[0];
          segment.ids = tree.search([spanStart, i]) as string[];
          segment.nested = nestedSpans;
          segments.push(segment);
        } else {

          let subSegment: Segment = {
            type,
            text: ''
          };

          for (const char of segment.text) {
            if (char === '\n') {
              if (subSegment.text.length > 0) {
                segments.push(subSegment);
              }
              segments.push({
                type: EntityType.NEW_LINE,
                text: String.fromCharCode(13, 10)
              });
              subSegment = {
                type,
                text: ''
              }
            } else {
              subSegment.text += char;
            }
          }
          if (subSegment.text.length > 0) {
            segments.push(subSegment);
          }

        }

        if (whiteSpaces.length > 0) {
          const plainTextCap: Segment = {
            text: whiteSpaces.join(''),
            type: EntityType.PLAIN
          };
          segments.push(plainTextCap);
          whiteSpaces = [];
        }
        spanStart = i;
        acmIntersections.clear();
      }
      lastIntersections = nextIntersections;
    }

    return segments;
  }

  private populateIntervalTree(entities: Entity[], entityMap: Map<string, [number, number]>) {
    return entities.reduce<IntervalTree>((t, entity) => {
      const start = entity.spans[0].start;
      const end = entity.spans[0].end - 1;
      const id = entity.label;
      const bounds = [start, end] as Bounds;
      entityMap.set(id, bounds);
      t.insert(bounds, id);
      return t;
    }, new IntervalTree());
  }

  ngAfterViewInit(): void {
    this.hideMetaTabOnMouseLeave();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  trackBy({ id }: { id: string }) {
    return id;
  }

  private hideMetaTabOnMouseLeave() {
    fromEvent<MouseEvent>(this.sectionElement.nativeElement, 'mouseover')
      .pipe(
        withLatestFrom(
          this.store.select(EntityReducers.selectHighlightedSpans)
        ),
        tap(([event, highlightedSpans]) => {
          if (highlightedSpans.length > 0) {
            this.store.dispatch(setHighlightedSpans({ ids: [] }))
          }
        }),
        takeWhile(() => this.alive)
      ).subscribe();
  }

  private intersectingArrays<T>(a: T[], b: T[]): boolean {
    if (a.length === 0 && b.length === 0) {
      return false;
    }
    return b.some(lastIntersection => a.indexOf(lastIntersection) !== -1);
  }

  private getNestedSpans(intersections: string[], entityMap: Map<string, [number, number]>, spanStart: number) {
    return intersections.reduce<{ [id: string]: Bounds; }>((map, nestedId) => {
      map[nestedId] = entityMap.get(nestedId).map(index => index - spanStart) as Bounds;
      return map;
    }, {});
  }
}
