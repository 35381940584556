import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private http: HttpClient;

  constructor(handler: HttpBackend, @Inject('env') private env: any) {
    this.http = new HttpClient(handler);
  }

  fetchConfig() {
    const configPath = this.env.configPath;
    return this.http.get<{
      emtelliproServer: string;
      iamServer: string;
      allowedExtensions: string[];
      enabledFeatures: string[];
    }>(configPath);
  }
}
