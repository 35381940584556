import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as VisualizerActions from '../visualizer/visualizer.actions';
import * as SectionActions from './section.actions';
import { Section } from './section.model';
import { sectionsFeatureKey } from './section.reducer';
import { NLPOutput , Name, Section as NlpSection} from 'pages-lib';


@Injectable()
export class SectionEffects {

  hydrateSections$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('[Reports] Fetch Report Success'),
      switchMap(({ data: nlpOutput }: { data: NLPOutput }) => {
        const allSections = this.getSections(nlpOutput)
        const sections = allSections
          .map(section => {
            const _section: Section = {
              id: section.label,
              subSectionIds: this.getSubSectionIds(section, allSections),
              label: section.label,
              level: section.level,
              name: section.name as Name,
              parent: section.parent,
              spans: section.spans,
              text: section.text
            };
            return _section;
          });
        const loadSections = SectionActions.loadSections({ sections });
        const notifyLoadingDone = VisualizerActions.storeHydrated({
          storeType: sectionsFeatureKey
        });
        return of(loadSections, notifyLoadingDone);
      })
    );
  });

  clear$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('[Reports] Cancel Fetch Report'),
      switchMap(() => of(SectionActions.clearSections()))
    );
  });

  constructor(private actions$: Actions) { }

  private getSections(nlpOutput: NLPOutput) {
    return nlpOutput.documents[0].locations.sections;
  }

  private getSubSectionIds(section: NlpSection, allSections: NlpSection[]): string[] {
    return allSections
      .filter(sec => sec.spans[0].end <= section.spans[0].end)
      .map(sec => sec.label);
  }

}
