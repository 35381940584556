import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Span } from './span.model';
import * as SpanActions from './span.actions';

export const spansFeatureKey = 'spans';

export interface State extends EntityState<Span> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Span> = createEntityAdapter<Span>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(SpanActions.addSpan,
    (state, action) => adapter.addOne(action.span, state)
  ),
  on(SpanActions.upsertSpan,
    (state, action) => adapter.upsertOne(action.span, state)
  ),
  on(SpanActions.addSpans,
    (state, action) => adapter.addMany(action.spans, state)
  ),
  on(SpanActions.upsertSpans,
    (state, action) => adapter.upsertMany(action.spans, state)
  ),
  on(SpanActions.updateSpan,
    (state, action) => adapter.updateOne(action.span, state)
  ),
  on(SpanActions.updateSpans,
    (state, action) => adapter.updateMany(action.spans, state)
  ),
  on(SpanActions.deleteSpan,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(SpanActions.deleteSpans,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(SpanActions.loadSpans,
    (state, action) => adapter.setAll(action.spans, state)
  ),
  on(SpanActions.clearSpans,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getSpansState = createFeatureSelector(spansFeatureKey);
export const selectAllSpans = createSelector(getSpansState, selectAll);
export const selectSpanEntities = createSelector(getSpansState, selectEntities);
export const selectVisibleSpans = createSelector(
  selectAllSpans,
  (spans) => {
    return spans.filter(span => span.visible);
  });
