import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Relation } from './relation.model';

export const loadRelations = createAction(
  '[Relation/API] Load Relations',
  props<{ relations: Relation[] }>()
);

export const addRelation = createAction(
  '[Relation/API] Add Relation',
  props<{ relation: Relation }>()
);

export const upsertRelation = createAction(
  '[Relation/API] Upsert Relation',
  props<{ relation: Relation }>()
);

export const addRelations = createAction(
  '[Relation/API] Add Relations',
  props<{ relations: Relation[] }>()
);

export const upsertRelations = createAction(
  '[Relation/API] Upsert Relations',
  props<{ relations: Relation[] }>()
);

export const updateRelation = createAction(
  '[Relation/API] Update Relation',
  props<{ relation: Update<Relation> }>()
);

export const updateRelations = createAction(
  '[Relation/API] Update Relations',
  props<{ relations: Update<Relation>[] }>()
);

export const deleteRelation = createAction(
  '[Relation/API] Delete Relation',
  props<{ id: string }>()
);

export const deleteRelations = createAction(
  '[Relation/API] Delete Relations',
  props<{ ids: string[] }>()
);

export const clearRelations = createAction(
  '[Relation/API] Clear Relations'
);

export const toggleRelationType = createAction(
  '[Relation/API] Toggle Relation',
  props<{ relationType: string }>()
);

export const toggleAllRelationTypes = createAction(
  '[Relation/API] Toggle All Relations',
  props<{ disableAllTypes: boolean }>()
);
