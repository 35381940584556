// Generated by https://quicktype.io

export interface NLPOutput {
    format: string;
    version: string;
    ['engine-version']: string;
    documents: Document[];
}

export interface Document {
    id: string;
    attributes: DocumentAttributes;
    processing_status: string;
    entities: Entities;
    concepts: { [key: string]: Concept[] };
    relations: Relations;
    locations: Locations;
    text?: string;
}

export interface DocumentAttributes {
    category: Category;
    subcategory: Category;
}

export interface Category {
    value: string;
    method?: string;
}

export interface Concept {
    label: string;
    concept_id: string;
    description: string;
}

export interface Entities {
    found: Found[];
    assumed: Assumed[];
}

export interface Assumed {
    label: string;
    value: string;
}

export interface Found {
    label: string;
    spans: Span[];
    section_name: Name;
    attributes: FoundAttributes;
    concept_links: string[];
    locations: string[];
    entity_type: EntityType;
    text: string[];
}

export interface FoundAttributes {
    polarity: Polarity;
    uncertainty: Uncertainty;
    question_status: QuestionStatus;
    measurement_unit?: string[];
    guidance: Guidance;
    heading_status: HeadingStatus
}

export enum Polarity {
    Asserted = "asserted",
    Negated = "negated",
}

export enum Uncertainty {
    Certain = "certain",
    Uncertain = "uncertain",
}

export enum QuestionStatus {
    NotQuestion = "not_question",
    IsQuestion = "is_question"
}

export enum Guidance {
    isGuidance = "is_guidance",
    notGuidance = "not_guidance"
}

export enum HeadingStatus {
    outside = "outside_heading",
    inside = "inside_heading"
}

export interface EntityType {
    emtelligent?: Emtelligent;
    radlex?: string;
    umls?: string;
    snomed?: Snomed;
    medcin?: string;
    umls_loinc?: string;
    umls_nci?: string;
}

export enum Emtelligent {
    Anatomicalsite = "anatomicalsite",
    Diseasedisorder = "diseasedisorder",
    Measurement = "measurement",
    Procedure = "procedure",
    QualifierValue = "qualifier value",
    Signsymptom = "signsymptom",
    Temporal = "temporal",
}

export enum Snomed {
    Attribute = "attribute",
    BodyStructure = "body structure",
    Disorder = "disorder",
    Finding = "finding",
    MorphologicAbnormality = "morphologic abnormality",
    Procedure = "procedure",
    QualifierValue = "qualifier value",
}

export enum Name {
    Comparison = "COMPARISON",
    Findings = "FINDINGS",
    Impression = "IMPRESSION",
    Indication = "INDICATION",
    Technique = "TECHNIQUE",
}

export interface Span {
    start: number;
    end: number;
}

export interface Locations {
    sections: Section[];
    sentences: Sentence[];
}

export interface Section {
    label: string;
    spans: Span[];
    name: Name;
    level: number;
    parent?: string;
    text: string[];
}

export interface Sentence {
    label: string;
    spans: Span[];
    sections: string[];
    text: string[];
}

export interface Relations {
    experiencers: Experiencer[];
    followups: Followup[];
    measurements: Measurement[];
    imagelinks: any[];
    qualifiers: Qualifier[];
    medications: Medications[];
    temporalities: Temporality[];
    reportedevents: ReportedEvent[];
    anatomicsites: AnatomicSite[];
}

export interface Experiencer {
    label: string;
    attributes: ExperiencerAttributes;
    args: ExperiencerArgs;
}

export interface ExperiencerArgs {
    experiencer: Experienced;
    experienced: Experienced;
}

export interface Experienced {
    ref: string;
    text: string[];
}

export interface ExperiencerAttributes {
    polarity: Polarity;
}

export interface Followup {
    label: string;
    attributes: ExperiencerAttributes;
    args: FollowupArgs;
}

export interface FollowupArgs {
    procedures: Experienced[];
    time_expression: Experienced;
    reasons: Experienced[];
}

export interface Measurement {
    label: string;
    attributes: MeasurementAttributes;
    args: MeasurementArgs;
}

export interface MeasurementArgs {
    subject: Experienced;
    value: Experienced;
}

export interface MeasurementAttributes {
}

export interface Qualifier {
    label: string;
    attributes: QualifierAttributes;
    args: QualifierArgs;
}

export interface QualifierArgs {
    qualifier: Experienced;
    qualifies: Experienced;
}

export interface QualifierAttributes {
    qualifier_type: string;
}

export interface Medications {
    args: MedicationsArgs;
    attributes: any;
    label: string;
}

export interface MedicationsArgs {
    dosage: Ref[];
    drug: Ref;
    frequency: Ref[];
    mode: Ref[];
    modifier: Ref[];
    necessity: Ref[];
    quantity: Ref[];
    route: Ref[];
    duration: Ref[];
    indication: Ref[];
    date_time: Ref[];
}

export interface Temporality {
    label: string;
    attributes: TemporalityAttributes;
    args: TemporalityArgs;
}

export interface TemporalityAttributes extends ExperiencerAttributes {
    category: string;
}

export interface TemporalityArgs {
    temporal_entity: Ref;
    subject: Ref;
    modifiers: Ref[];
}

export interface ReportedEvent {
    label: string;
    attributes: ReportedEventAttributes;
    args: ReportedEventArgs;
}

export interface AnatomicSite {
    label: string;
    args: AnatomicSiteArgs;
}

export interface ReportedEventAttributes {
    category: string;
    polarity: Polarity;
}

export interface ReportedEventArgs {
    subject: Ref;
    to_entities: Ref[];
    from_entities: Ref[];
    methods: Ref[];
    time_expressions: Ref[];
}

export interface AnatomicSiteArgs {
    site: Ref;
    situated_entity: Ref;
}

export interface Ref {
    ref: string;
    text: string[];
}
