import { createAction, props } from '@ngrx/store';
import { DisplayState } from './visualizer.reducer';

export const storeHydrated = createAction(
  '[Visualizer] Store Hydrated',
  props<{
    storeType: string
  }>()
);

export const clearHydratedStores = createAction(
  '[Visualizer] Clear Hydrated Stores'
);

export const setOntologiesSelectorDisplay = createAction(
  '[Visualizer] Set Ontologies Selector Display',
  props<{ display: DisplayState }>()
);

export const setEntityTypesSelectorDisplay = createAction(
  '[Visualizer] Set Entity Type Selector Display',
  props<{ display: DisplayState }>()
);

export const setRelationTypesSelectorDisplay = createAction(
  '[Visualizer] Set Relation Type Selector Display',
  props<{ display: DisplayState }>()
);


