import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { sessionExpired } from '../store/user.actions';
import { GetUserStatusSuccess, GetUserSuccess, SignInSuccess } from './user.api.model';

interface AppConfigProvider {
  iamServer$: Observable<string>;
  emtelliproServer$: Observable<string>;
}

@Injectable()
export class UserService {

  private openHttp: HttpClient;
  private signOutTimer;

  constructor(
    handler: HttpBackend,
    private authenticatedHttp: HttpClient,
    private store: Store,
    @Inject('config') private config: AppConfigProvider) {
    this.openHttp = new HttpClient(handler);
  }

  public signIn(username: string, password: string) {
    return this.config.iamServer$.pipe(
      switchMap(host => {
        const url = `${host}/login`;
        const body = {
          user_name: username,
          password
        };
        return this.openHttp.post<SignInSuccess>(url, body);
      })
    )
  }

  public signOut() {
    return this.config.iamServer$.pipe(
      switchMap(host => {
        const url = `${host}/logout`;
        return this.authenticatedHttp.post(url, {});
      })
    );
  }

  public getUserInfo(userId: string, token: string) {
    return this.config.iamServer$.pipe(
      switchMap(host => {
        const url = `${host}/authenticated-user-info`;
        const headers = new HttpHeaders({
          Authorization: `Bearer ${token}`,
        });
        const options = {
          headers
        }
        return this.openHttp.get<GetUserSuccess>(url, options);
      })
    )
  }

  public getUserStatus() {
    return this.config.iamServer$.pipe(
      switchMap(host => {
        const url = `${host}/user-status`;
        return this.authenticatedHttp.get<GetUserStatusSuccess>(url);
      })
    )
  }

  public changePassword(username: string, password: string, newPassword: string) {
    return this.config.iamServer$.pipe(
      switchMap(host => {
        const url = `${host}/change-password`;
        const body = {
          user_name: username,
          old_password: password,
          new_password: newPassword
        };
        return this.openHttp.post(url, body);
      })
    )
  }

  public forgotPassword(username: string) {
    return this.config.iamServer$.pipe(
      switchMap(host => {
        const url = `${host}/forgot-password`;
        const body = {
          user_name: username
        };
        return this.openHttp.patch(url, body);
      })
    )
  }

  public signup({ email, password, eula, firstName, lastName, companyName, jobTitle }: {
    email: string;
    password: string;
    eula: string;
    firstName: string;
    lastName: string;
    companyName: string;
    jobTitle: string;
  }) {
    return this.config.iamServer$.pipe(
      switchMap(host => {
        const url = `${host}/sign-up`;
        const body = {
          user_name: email,
          password,
          EULA: eula,
          first_name: firstName,
          last_name: lastName,
          company_name: companyName,
          job_title: jobTitle
        }
        return this.openHttp.post(url, body);
      })
    );
  }

  public verifyEmail(email: string, verificationCode: string) {
    return this.config.iamServer$.pipe(
      switchMap(host => {
        const url = `${host}/verify-email`;
        const body = {
          email,
          verification_code: verificationCode,
        }
        return this.authenticatedHttp.patch(url, body);
      })
    );
  }

  public sendVerificationEmail() {
    return this.config.iamServer$.pipe(
      switchMap(host => {
        const url = `${host}/send-verification-email`;
        const body = {};
        return this.authenticatedHttp.patch(url, body);
      })
    );
  }

  public sendForgotPasswordEmail(email: string) {
    return this.config.iamServer$.pipe(
      switchMap(host => {
        const url = `${host}/forgot-password`;
        const body = {
          user_name: email
        };
        return this.authenticatedHttp.patch(url, body);
      })
    );
  }

  public getEula() {
    return this.config.iamServer$.pipe(
      switchMap(host => {
        const url = `${host}/eula`;
        return this.openHttp.get<{ EULA: string }>(url);
      })
    );
  }

  public acceptEula(eula: string) {
    return this.config.iamServer$.pipe(
      switchMap(host => {
        const url = `${host}/eula`;
        const body = {
          EULA: eula
        }
        return this.authenticatedHttp.patch(url, body);
      })
    );
  }

  public setSignOutTimer(expirationDuration: number) {
    this.clearSignOutTimer();
    this.signOutTimer = setTimeout(() => {
      this.store.dispatch(sessionExpired());
    }, Math.min(expirationDuration, 2147483647));
  }

  private clearSignOutTimer() {
    if (!this.signOutTimer) {
      return;
    }
    clearTimeout(this.signOutTimer);
  }
}
